@use "../../../../variables" as var;

.Feedback {
    border: 1px solid var.$borderGray;
    background-color: var.$backgroundDarker;
    padding: var.$fontSize;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
}

.stars {
    text-align: center;
    .star {
        display: inline-block;
        height: var.$fontSize * 2;
        width: var.$fontSize * 2;
        background-image: url(../../../../assets/icon_rating_star_good.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.text {
    flex: 1;
    margin-top: var.$fontSize;
    text-align: center;
    font-size: var.$fontSizeSmall;
}

.signature {
    text-align: center;
    margin-top: var.$fontSize;
    font-size: var.$fontSizeHint;
}