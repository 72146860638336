.checkbox {

  .container {
    display: inline-block;
    position: relative;
    padding-left: 36px;
    margin-right: 32px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.input-vertical {
      display: block;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #DCDFEB;
    border-radius: 3px;
  }

  .container input:checked ~ .checkmark {
    background-color: #2196F3;
    border: none;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 4px;
    left: 8px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}