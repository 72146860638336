
$fontLarge:                     22px;
$fontSize:                      16px;
$fontSizeSmall:                 14px;
$fontSizeHint:                  12px;

$bannerFontSize:                20px;

$textBlack:                     #000000;
$backgroundWhite:               #FFFFFF;
$backgroundDarker:              #F8FCFE;
$backgroundLightYellow:         #F4EB5E;
$primaryBlue:                   #00A6E4;
$secondaryYellow:               #FFC301;
$borderGray:                    #CCCCCC;
$modalBackground:               rgba(0,0,0,0.5);

$breakpointXl:                  1400;
$breakpointLg:                  1200;
$breakpointNorm:                768;
$breakpointSm:                  480;