.radio-buttons-group {

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .container {
    display: inline-block;
    position: relative;
    padding-left: 24px;
    margin-right: 32px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.input-vertical {
      display: block;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #DCDFEB;
    border-radius: 50%;
  }

  // .container:hover input ~ .checkmark {
  //   background-color: #eee;
  // }

  .container input:checked ~ .checkmark {
    background-color: #2196F3;
    border: none;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}