.login-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-box {
    position: relative;
    padding: 128px 108px 92px;
    background: #fff;
    width: 520px;
    box-shadow: 5px 5px 20px #E3E5EE;
    border-radius: 10px;

    .language-dropdown-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .input-wrapper {
      margin-top: 24px;
    }

    .link-wrapper {
      text-align: right;
      margin-top: 8px;

      a {
        font-size: 14px;
      }
    }

    .login-error {
      margin-top: 16px;
      font-size: 14px;
      color: #D21C1C;
    }

    .button-wrapper {
      margin-top: 48px;
    }
  }
}