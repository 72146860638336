.sortable-header {
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 17px;
  padding: 0 16px;


  .header-arrows {
    display: flex;
    flex-direction: column;

    .sort-arrow {
      opacity: 0.5;
    }
  
    .sort-arrow-selected {
      opacity: 1;
      filter: brightness(1) saturate(50%);
    }
  }
}