@use '../../variables' as var;

.cookieConsent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 85vh;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFFFFF;
  padding: 10px;
  text-align: center;
  z-index: 1000;
  overflow: auto;

  .button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
  }

  .types {
    list-style: none;
  }

  .labelka {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 8px;

    li {
    }

    @media screen and (max-width: var.$breakpointNorm+px) {
      flex-direction: column;
    }
  }

  .hidden {
    display: none;
  }

  .moreButton {
    margin: 16px;
    text-decoration: underline;
    cursor: pointer;
    font-style: italic;
    font-size: 90%;
  }

  .description {
    margin: 16px;
    text-align: justify;
  }

  .buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .saveButton {
      border: none;
      background-color: #00a6e4;
      border-radius: 8px;
      color: #fff;
      display: inline-block;
      padding: 8px 16px;
      font-size: 16px;
      text-align: center;
    }

    .declineButton {
      border: none;
      background-color: transparent;
      border-radius: 8px;
      color: #fff;
      display: inline-block;
      padding: 8px;
      text-align: center;
    }
  }
}

