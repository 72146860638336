.buy-without-registration-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content-wrapper {
    margin: 0 auto;
    max-width: 400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }

    @media screen and (max-width: 768px) {
      max-width: none;
    }
  }

  h1 {
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .register-link {
    
    a {
      color: #139CFF;
    }
  }

  .button-wrapper {
    margin-top: 48px;

    .button {
      border-radius: 20px;
    }
  }
}