@use "../../variables" as var;
@use "sass:math";

.CallToAction {
    background-color: var.$primaryBlue;

    .ctaContent {
        margin: 0 auto;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;

        max-width: var.$breakpointLg+px;

        @media screen and (max-width: var.$breakpointNorm+px) {
            min-height: unset;
        }

        .firstSection {
            flex: 4;
            text-align: left;
            padding: var.$fontSize*2;
            position: relative;
            z-index: 1;
            .ctaLine1 {
                font-size: var.$fontSize * 2.5;
                color: var.$secondaryYellow;
                text-transform: uppercase;
                text-shadow: -3px 2px var.$textBlack;
                @media screen and (max-width: var.$breakpointLg+px) {
                    font-size: var.$fontSize * 2;
                }
                @media screen and (max-width: var.$breakpointNorm+px) {
                    font-size: var.$fontSize * 1.5;
                }
            }
            .ctaLine2 {
                font-size: var.$fontSize * 1;
                line-height: 1.35;
                margin-top: 1em;
                @media screen and (max-width: var.$breakpointLg+px) {
                    //font-size: var.$fontSize * 1.25;
                }
                @media screen and (max-width: var.$breakpointNorm+px) {
                    //font-size: var.$fontSize;
                }
                @media screen and (max-width: var.$breakpointSm+px) {
                    //font-size: var.$fontSize * 0.9;
                }
            }
            .ctaLine3 {
                margin-top: var.$fontSize*2;
                text-align: center;
            }
            .ctaButton {
                font-size: var.$fontSize*1.35;
                font-weight: bold;
                padding: var.$fontSize*1.5 var.$fontSize * 4;
                text-transform: uppercase;
                background-color: lighten(var.$primaryBlue, 50%);
                border: none;
                box-shadow: -5px 5px 10px 2px rgba(0, 0, 0, 1);
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                cursor: pointer;
                @media screen and (max-width: var.$breakpointLg+px) {
                    font-size: var.$fontSize * 0.9;
                    padding: var.$fontSize*1.2 var.$fontSize * 3;
                }
                @media screen and (max-width: var.$breakpointNorm+px) {
                    display: none;
                }
            }
        }

        .secondSection {
            position: relative;
            flex: 5;
            text-align: center;
            z-index: 1;
            @media screen and (max-width: var.$breakpointNorm+px) {
                display: none;
            }
        }
    }
}

.layeredContainer {
    margin: 2em;
    display: inline-block;
    height: 45vw;
    width: 45vw;
    max-height: math.div(var.$breakpointNorm, 1.5)+px;
    max-width: math.div(var.$breakpointNorm, 1.5)+px;
    position: relative;
    > div {
        position: absolute;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .redExplosion {
        background-image: url("../../assets/cta_red_explosion.svg");
    }
    .bookCover {
        background-size: contain;
    }
    .omg {
        background-image: url("../../assets/cta_omg.svg");
        background-size: 50%;
        background-position: top left;
    }
    .clouds {
        background-image: url("../../assets/cta_clouds.svg");
        background-position: bottom center;
    }
}

.frills {
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../assets/cta_frills_desktop.svg");
    transform: translateY(-15px);
    z-index: -1;
    height: 16vw;

    svg {
        filter: drop-shadow(3px 5px 2px rgb(0,0,0, 0.4));
    }
    @media screen and (max-width: var.$breakpointNorm+px){
        display: none;
    }
}

.star {
    position: absolute;
    background-image: url("../../assets/cta_star.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    @media screen and (max-width: var.$breakpointNorm+px){
        display: none;
    }
    &.star1 {
        height: 50px;
        width: 50px;
        top: -5%;
        left: 10%;
    }
    &.star2 {
        height: 40px;
        width: 40px;
        bottom: -20%;
        right: 30%;
        transform: rotateZ(40deg);
    }
    &.star3 {
        height: 15px;
        width: 15px;
        top: 15%;
        right: 23%;
        transform: rotateZ(20deg);
    }
    &.star4 {
        height: 20px;
        width: 20px;
        bottom: 15%;
        left: 30%;
        transform: rotateZ(-40deg);
    }
}
