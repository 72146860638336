.orders-details-page {
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    .title-line {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {

        .status {
          margin-left: 32px;
          background: rgba(19, 156, 255, 0.1);
          border-radius: 3px;
          font-weight: 600;
          color: #139CFF;
          height: 40px;
          padding: 0 12px;
          display: flex;
          align-items: center;
        }
      }

      .right {
        display: flex;
        
        .select-wrapper {
          width: 300px;

          .select-input {
            width: 300px;
            background: #fff;
          }
        }

        .button {
          margin-left: 16px;

          &.button-inactive {
            color: #95A4AE;
            background: #F9F9F9;
            pointer-events: none;
          }
        }
      }
    }

    .date-line {
      margin-top: 8px;

      img {
        margin-right: 4px;
        position: relative;
        top: 2px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
      }
    }

    .columns-2-1 {
      margin-top: 32px;
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 24px;

      .box {
        background: #fff;
        border-radius: 5px;
        padding: 24px;

        .label {
          font-weight: 500;
          color: #95A4AE;
        }

        .text-bigger {
          font-weight: 500;
          font-size: 18px;
        }

        .text {

        }

        hr {
          display: block;
          border: 0;
          border-bottom: 1px solid #EFF0F6;
          margin: 24px 0;

          &.hr-smaller {
            margin: 16px 0;
          }
        }

        .order-history-item-wrapper {

          &:last-of-type {

            .hr-smaller {
              display: none;
            }
          }

          .order-history-item {
            display: flex;
            align-items: center;
  
            .item-date {
              width: 82px;
            }
  
            .circle {
              display: inline-block;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #000;
              margin: 0 12px;
            }
          }
        }
      }

      .table-header {
        display: flex;

        .table-header-cell {
          font-weight: 500;
          font-size: 16px;
          color: #95A4AE;

          &:nth-of-type(1) {
            flex: 1;
          }

          &:nth-of-type(2) {
            width: 130px;
            text-align: right;
          }

          &:nth-of-type(3) {
            width: 100px;
            text-align: right;
          }

          &:nth-of-type(4) {
            width: 100px;
            text-align: right;
          }

          &:nth-of-type(5) {
            width: 100px;
            text-align: right;
          }

          &:nth-of-type(6) {
            width: 50px;
          }
        }
      }
      
      .table-rows {
        margin-top: 24px;

        .table-row-wrapper {

          &.with-background {
            background: rgba(239, 240, 246, 0.4);
            margin-left: -24px;
            margin-right: -24px;
            padding-left: 24px;
            padding-right: 24px;
          }

          .table-row {
            margin-bottom: 24px;
            padding-top: 24px;
            border-top: 1px solid #EFF0F6;
            display: flex;
            align-items: center;

            &:first-of-type {
              border-top: 0;
            }

            .table-row-cell {
    
              &:nth-of-type(1) {
                flex: 1;
                display: flex;

                & > img {
                  width: 116px;
                  height: 84px;
                  object-fit: cover;
                }

                .book-detals {
                  margin-left: 12px;
                  margin-top: 8px;

                  .book-title {
                    font-weight: 500;
                    font-size: 18px;
                  }

                  .book-type {
                    margin-top: 4px;
                    font-size: 14px;
                    color: #95A4AE;
                  }

                  .book-preview {
                    margin-top: 24px;
                    font-size: 14px;
                    color: #139CFF;
                    cursor: pointer;

                    img {
                      position: relative;
                      top: 2px;
                      margin-right: 4px;
                    }
                  }
                }
              }
    
              &:nth-of-type(2) {
                width: 130px;
                text-align: right;
              }
    
              &:nth-of-type(3) {
                width: 100px;
                text-align: right;
              }
    
              &:nth-of-type(4) {
                width: 100px;
                text-align: right;
              }

              &:nth-of-type(5) {
                width: 100px;
                text-align: right;
              }
    
              &:nth-of-type(6) {
                width: 50px;
                text-align: right;

                img {
                  border: 1px solid #DCDFEB;
                  border-radius: 3px;
                  cursor: pointer;

                  &.icon-rotated {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }

          .personalization-details {
            padding-bottom: 24px;
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 24px;

            .left {

              .photo-area {
                margin-top: 8px;
                display: flex;
                align-items: center;

                img {
                  width: 80px;
                  height: 80px;
                  object-fit: cover;
                  border-radius: 3px;
                  margin-right: 8px;
                }

                .photo-details {

                  div:nth-of-type(1) {
                    font-size: 14px;
                    color: #363840;
                  }

                  div:nth-of-type(2) {
                    margin-top: 4px;
                    font-size: 12px;
                    color: #86888C;
                  }
                }
              }

              .upload-button {
                margin-top: 8px;
                color: #139CFF;
                font-size: 14px;
            
                .inputfile {
                  width: 0.1px;
                  height: 0.1px;
                  opacity: 0;
                  overflow: hidden;
                }
            
                label {
                  cursor: pointer;
                }
              }
            }

            .right {

              .button-wrapper {
                margin-top: 16px;
                display: flex;
                justify-content: flex-end;
              }
            }

            .label {
              font-weight: normal;
              font-size: 14px;
              color: #252A31;
            }
          }
        }
      }

      .summary-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 50px;
        padding: 16px 0;

        &.with-background {
          background: #FCFCFD;
          margin: 0 -24px -24px -24px;
          border-radius: 0 0 5px 5px;
          padding: 16px 74px 16px 24px;
          border-top: 1px solid #EFF0F6;
          font-weight: 500;
        }

        .summary-line-label {

        }

        .summary-line-value {
          font-weight: 500;

          &.green-text {
            color: #41BA9B;
          }
        }
      }

    }
  }
}