.personalize-page {
  background: #fff;

  .book-data-section {

    .section-inner {
      margin: 0 auto;
      padding: 48px 128px 24px;
      max-width: 1400px;

      @media screen and (max-width: 1200px) {
        padding: 48px 32px 24px;
      }

      @media screen and (max-width: 768px) {
        padding: 32px;
      }

      .columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 48px;

        @media screen and (max-width: 1200px) {
          display: block;
        }

        & > .left {

          @media screen and (max-width: 1200px) {
            margin: 0 auto;
            max-width: 600px;
          }

          .book-preview {
            display: flex;
            width: 600px;
            user-select: none;
            // box-shadow: 0 0 10px;

            @media screen and (max-width: 1200px) {
              margin: 0 auto;
            }

            @media screen and (max-width: 768px) {
              width: 80vw;
              margin: 0 auto;
            }

            .page {
              width: 300px;
              height: 420px;
              position: relative;

              @media screen and (max-width: 768px) {
                width: 40vw;
                height: 56vw;
              }

              .inner-font-linotte {
                font-family: 'Linotte-SemiBold';
              }

              .inner-font-pol-wonder-comic {
                font-family: 'POL_Wonder_Comic';
              }

              &.page-left {
                background: #fff;

                .left-page-shadow {
                  position: absolute;
                  top: 3%;
                  bottom: 3%;
                  right: 0;
                  width: 50%;
                  background: linear-gradient(90deg, rgba(42, 72, 84, 0) 0%, rgba(42, 72, 84, 0) 75.14%, rgba(42, 72, 84, 0.4) 100%);
                  z-index: 2;
                }

                .left-bottom-shadow {
                  position: absolute;
                  left: 5%;
                  right: 0;
                  top: 3.1%;
                  bottom: 3.1%;
                  box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.4);
                  z-index: 3;
                }
              }

              &.page-right {
                background: #fff;
                position: relative;

                .right-page-shadow {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  width: 50%;
                  background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 32.7%, rgba(255, 255, 255, 0) 100%);
                  z-index: 2;
                }

                .right-bottom-shadow {
                  position: absolute;
                  left: 0;
                  right: 4.8%;
                  top: 3.1%;
                  bottom: 3.1%;
                  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.4);
                  z-index: 2;
                }
              }

              .page-change-arrow {
                width: 56px;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.4);
                cursor: pointer;
                position: absolute;
                top: calc(50% - 28px);
                z-index: 1;

                @media screen and (max-width: 768px) {
                  width: 36px;
                  height: 36px;
                }

                img {
                  height: 38px;
                  width: 38px;
                  top: 9px;
                  left: 9px;

                  @media screen and (max-width: 768px) {
                    height: 24px;
                    width: 24px;
                    top: 6px;
                    left: 6px;
                  }
                }

                &.arrow-left {
                  left: -16px;
                }

                &.arrow-right {
                  right: -16px;
                }
              }

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 0;

                &.cover-background {
                  width: 96.2%;
                  height: 94%;
                  top: 3%;
                }
              }

              .page-text {
                position: absolute;
                z-index: 1;
                font-size: 9px;

                @media screen and (max-width: 768px) {
                  font-size: 1.2vw;
                }

                &.right-page-top-left {
                  top: 11.1%;
                  left: 11.1%;
                  width: 48.9%;
                  height: 18.8%;
                }

                &.right-page-botton-left {
                  top: 55.1%;
                  left: 11.1%;
                  width: 48.9%;
                  height: 18.8%;
                }

                &.right-page-top-right {
                  top: 30.1%;
                  left: 33.4%;
                  width: 48.9%;
                  height: 18.8%;
                }

                &.right-page-botton-right {
                  top: 70.1%;
                  left: 33.4%;
                  width: 48.9%;
                  height: 18.8%;
                }

                &.left-page-top-left {
                  top: 11.1%;
                  left: 17.75%;
                  width: 44.4%;
                  height: 18.8%;
                }

                &.left-page-botton-left {
                  top: 55.1%;
                  left: 17.75%;
                  width: 48.9%;
                  height: 18.8%;
                }

                &.left-page-top-right {
                  top: 30.1%;
                  left: 40.05%;
                  width: 48.9%;
                  height: 18.8%;
                }

                &.left-page-botton-right {
                  top: 70.1%;
                  left: 40.05%;
                  width: 48.9%;
                  height: 18.8%;
                }

                &.cover-text {
                  // top: 33.7%;
                  // left: 14.3%;
                  // width: 71.4%;
                  // height: 8.4%;
                  top: 36.5%;
                  left: 0;
                  width: 96.2%;
                  height: 8.4%;
                  font-size: 24px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  @media screen and (max-width: 768px) {
                    font-size: 3vw;
                  }
                }

                &.dedication-text {
                  top: 61.25%;
                  left: 22.5%;
                  width: 48.9%;
                  height: 25%;
                }

                &.thanks-page-text {
                  top: 61.25%;
                  left: 29.5%;
                  width: 48.9%;
                  height: 25%;
                }
              }

              .page-photo {
                position: absolute;

                &.right-page-top-left {
                  top: 19.1%;
                  left: 15.2%;
                  width: 22%;
                  height: 15.6%;
                }

                &.right-page-botton-left {
                  top: 50.3%;
                  left: 24.6%;
                  width: 22%;
                  height: 15.6%;
                }

                &.right-page-top-right {
                  top: 11.1%;
                  left: 62.3%;
                  width: 22%;
                  height: 15.6%;
                }

                &.right-page-botton-right {
                  top: 62.9%;
                  left: 51.2%;
                  width: 22%;
                  height: 15.6%;
                }

                &.left-page-top-left {
                  top: 19.1%;
                  left: 22%;
                  width: 22%;
                  height: 15.6%;
                }

                &.left-page-botton-left {
                  top: 50.3%;
                  left: 31.3%;
                  width: 22%;
                  height: 15.6%;
                }

                &.left-page-top-right {
                  top: 11.2%;
                  left: 68.7%;
                  width: 22%;
                  height: 15.6%;
                }

                &.left-page-botton-right {
                  top: 62.9%;
                  left: 57.8%;
                  width: 22%;
                  height: 15.6%;
                }

                &.cover-photo {
                  top: 51.5%;
                  left: 47.4%;
                  width: 30.5%;
                  height: 21.6%;
                }

                &.dedication-photo {
                  top: 22.9%;
                  left: 30.2%;
                  width: 33.3%;
                  height: 23.4%;
                }

                &.thanks-page-photo {
                  top: 22.9%;
                  left: 36.5%;
                  width: 33.3%;
                  height: 23.4%;
                }
              }
            }
          }

          h3 {
            margin-top: 32px;

            @media screen and (max-width: 768px) {
              margin-top: 16px;
            }
          }

          .book-description {
            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }

        .book-price-line {
          margin-top: 24px;

          @media screen and (max-width: 768px) {
            margin-top: 12px;
          }

          .book-price {
            font-weight: 500;
            font-size: 32px;

            @media screen and (max-width: 1200px) {
              font-size: 24px;
            }
          }

          .book-price-label {
            font-size: 13px;
            color: #95A4AE;
            margin-left: 8px;
          }
        }

        & > .right {
          padding-left: 32px;
          padding-bottom: 64px;
          position: relative;
          overflow: hidden;

          @media screen and (max-width: 1200px) {
            padding-left: 0;
            margin: 48px auto 0;
            max-width: 600px;
          }

          @media screen and (max-width: 768px) {
            padding-bottom: 0;
            margin-top: 16px;
          }

          &:before {
            content: "";
            position: absolute;
            border-left: 8px dashed #EFF0F6;
            top: -4px;
            bottom: -4px;
            left: -5px;
            right: -4px;
            z-index: 1;
            pointer-events: none;

            @media screen and (max-width: 1200px) {
              display: none;
            }
          }

          .input-wrapper {
            margin-top: 24px;

            .input-label {
              display: flex;
              align-items: center;
              margin-bottom: 12px;

              .input-label-number {
                background: #139CFF;
                color: #fff;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 3px;
              }

              .input-label-text {
                padding-top: 3px;
                margin-left: 8px;
                font-size: 18px;

                @media screen and (max-width: 768px) {
                  font-size: 16px;
                }
              }
            }

            .photo-info-message {
              font-size: 12px;
              margin-bottom: 16px;
            }

            .name-input {
              background: #FFFFFF;
              border-radius: 10px;
              color: #000;
              border: 0;
              height: 48px;
              padding: 0 24px;
              font-size: 16px;
              width: 100%;
              margin-right: 16px;
              flex: 1;
              border: 1px solid #DCDFEB;

              &::placeholder {
                color: #95A4AE;
              }
            }

            .name-info-message {
              font-size: 12px;
              margin-top: 8px;
              margin-bottom: 16px;
            }

            .gender-options {
              width: fit-content;
              height: 48px;
              border-radius: 10px;
              background: #fff;
              display: flex;
              align-items: center;
              border: 1px solid #DCDFEB;

              .gender-option {
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                padding: 0 16px;
                color: #95A4AE;
                font-size: 16px;
                cursor: pointer;
                user-select: none;

                &.option-active {
                  color: #139CFF;
                }

                &:nth-of-type(1) {
                  border-right: 1px solid #EFF0F6;
                }
              }
            }

            .textarea-input {
              width: 100%;
              height: 114px;
              border-radius: 5px;
              border: 1px solid #DCDFEB;
              padding: 12px;
              font-size: 16px;
              resize: none;

              &::placeholder {
                color: #95A4AE;
              }
            }

            .textarea-counter {
              color: #95A4AE;
              font-size: 12px;
              text-align: right;
            }
          }

          .button-wrapper {
            margin-top: 32px;

            .button {
              border-radius: 10px;
              height: fit-content;
              padding: 8px 16px;

              span:nth-of-type(2) {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
  }

  .advantages-section {

    .section-inner {
      margin: 0 auto;
      padding: 32px 128px 48px;
      max-width: 1400px;

      @media screen and (max-width: 1200px) {
        padding: 32px 32px 48px;
        max-width: 760px;
      }

      h2 {
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }

      .advantages-grid {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 32px;

        @media screen and (max-width: 1200px) {
          grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        .advantages-grid-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          img {
            height: 100px;
            width: auto;
            margin-bottom: 24px;
          }

        }
      }
    }
  }
}
