.address-data-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content-wrapper {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }
  }

  .columns {
    display: flex;

    @media screen and (max-width: 1200px) {
      justify-content: center;
    }

    .left {
      flex: 1;

      @media screen and (max-width: 1200px) {
        max-width: 480px;
      }
      
      .menu {
        display: flex;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .menu-item {
          cursor: pointer;
          font-size: 20px;
          font-weight: 500;
          padding-bottom: 8px;
          border-bottom: 2px solid #E5E5E5;

          @media screen and (max-width: 768px) {
            width: 50%;
            font-size: 15px;
          }

          &.empty-item {
            width: 48px;

            @media screen and (max-width: 768px) {
              width: 16px;
            }
          }

          &.active {
            color: #139CFF;
            border-bottom: 2px solid #139CFF;
          }
        }
      }

      .form-wrapper {
        margin-top: 32px;

        .input-wrapper {
          margin-top: 16px;
          display: flex;

          @media screen and (max-width: 768px) {
            display: block;
          }

          & > div:nth-of-type(2) {
            margin-left: 16px;

            @media screen and (max-width: 768px) {
              margin-left: 0;
              margin-top: 16px;
            }
          }

          .text-input {
            width: 100%;
          }

          .select-input {
            width: 100%;
          }
        }

        .button-wrapper {
          margin-top: 24px;
          display: flex;
          justify-content: flex-end;

          .button {
            border-radius: 20px;
            padding: 0 48px;

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }

    .right {
      flex: 1;

      @media screen and (max-width: 1200px) {
        flex: 0;
      }
    }
  }

  .radio-buttons-group .checkmark {
    top: 4px;
  }
}