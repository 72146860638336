@use "../../../../variables" as var;

.FeedbackList {
    padding: var.$fontSize*4 var.$fontSize*4;
    background-color: var.$backgroundWhite;
    position: relative;

    .FeedbackListTitle {
        text-align: center;
        margin-bottom: var.$fontSize;
    }
}

.SwiperButton {
    height: var.$fontSize*2;
    width: var.$fontSize*2;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    top: 50%;
    z-index: 1;

    &.SwiperButtonNext {
        background-image: url(../../../../assets/icon_swipe_next_active.svg);
        right: var.$fontSize;
    }
    &.SwiperButtonPrev {
        background-image: url(../../../../assets/icon_swipe_prev_active.svg);
        left: var.$fontSize;
    }
}

.Slide {
    height: unset;
}