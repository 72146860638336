@use "../../../variables" as var;

// Force aspect ratio 100%
.SingleBookTileWrapper {
    display: inline-block;
    position: relative;

    &:before{
        content: "";
        display:block;
        margin-top: 100%;
    }

    .SingleBookTile {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.SingleBookTile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;

    box-shadow: 0 0 11px 0 rgba(66, 68, 90, 0.33);
    border-radius: var.$fontSize * 3;
    @media screen and (max-width: var.$breakpointNorm+px){
        box-shadow: none;
        border-radius: unset;
    }

    padding: 2vw;

    .Spacer {
        height: 2.5%;
    }

    .CoverImage {
        position: relative;
        flex: 1;
        height: 100%;
        width: 100%;
        .stackItem {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
        .mobileOnly {
            display: none;
            @media screen and (max-width: var.$breakpointNorm+px){
                display: block;
            }
        }
    }

    .LinkWrapper {
        height: 20%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        .ProductLink {
            display: inline-block;
            border-radius: var.$fontSize * 3;
            background-color: var.$primaryBlue;
            color: var.$backgroundWhite;
            padding: var.$fontSize 0;
            text-align: center;
            font-size: var.$fontSize;
            @media screen and (max-width: var.$breakpointNorm+px){
                background-color: lighten(var.$primaryBlue, 50%);
                color: var.$textBlack
            }
        }
    }
}
