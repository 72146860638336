.existing-book-editor {
  position: relative;
  
  .top-bar {
    border-top: 1px solid #E3E4EF;
    background: #fff;
    height: 70px;
    min-height: 70px;

    .top-bar-inner {
      margin: 0 auto;
      padding: 0 128px;
      max-width: 1400px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        height: 100%;
        display: flex;
        align-items: center;

        img {
          margin-left: 24px;
          cursor: pointer;
        }
      }

      .right {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .pages {
    margin: 48px auto 0;
    padding: 0 32px;
    max-width: 964px;

    &.cover-font-coiny-regular {
      .cover-text {
        font-family: 'coiny-regular';
      }
    }

    &.cover-font-pol-komikax {
      .cover-text {
        font-family: 'POL_KOMIKAX';
      }
    }

    &.inner-font-linotte {
      .text {
        font-family: 'Linotte-SemiBold';
      }
    }

    &.inner-font-pol-wonder-comic {
      .text {
        font-family: 'POL_Wonder_Comic';
      }
    }

    .page-wrapper {
      
    }
    
    .separator {
      padding: 32px 0;
      display: flex;
      align-items: center;

      .separator-line {
        flex: 1;
        display: inline-block;
        border-bottom: 0.5px solid rgba(158, 162, 172, 0.404365);;
      }

      .separator-text {
        margin: 0 48px;
        font-size: 14px;
        color: #9EA2AC;
      }
    }
  }
}