.www-vouchers-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .input-wrapper {

    &.w400 {
      width: 400px;
    }

    &.w285 {
      width: 285px;
    }

    &.w250 {
      width: 250px;
    }

    &.disabled {
      pointer-events: none;

      .input-label {
        color: #aaa;
      }

      input {
        background: #fafafa;

        &::placeholder {
          color: #ccc;
        }
      }
    }
  }

  .button-wrapper {
    width: 400px;
  }
}