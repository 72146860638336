.contact-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }
  }

  .columns {
    margin-top: 24px;
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 0;
    }

    h2 {
      @media screen and (max-width: 768px) {
        font-size: 20px;
        text-align: center;
      }
    }
    
    .left {
      flex: 1;
      margin-right: 128px;

      @media screen and (max-width: 1200px) {
        margin-right: 32px;
      }

      @media screen and (max-width: 768px) {
        order: 1;
        margin-right: 0;
      }

      .w360 {
        max-width: 360px;

        @media screen and (max-width: 768px) {
          max-width: none;
        }
      }

      .captcha-wrapper {

      }

      .button-wrapper {

        .button {
          width: 300px;
        }
      }
    }

    .right {
      flex: 1;
      border: 1px solid #DCDFEB;
      border-radius: 20px;
      padding: 0 32px 64px;
      height: fit-content;
      position: relative;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        order: 0;
        border: none;
        padding: 0;
        margin-bottom: 32px;
        border-radius: 0;
      }

      img {
        width: calc(100% + 64px);
        margin-left: -32px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .contact-info {
        max-width: 300px;
        margin-top: 24px;

        @media screen and (max-width: 768px) {
         max-width: none;
         margin-top: 0;
         margin-bottom: 32px;
        }
      }
    }
  }
}