.text-input {

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  input {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #DCDFEB;
    padding: 0 12px;
    font-size: 16px;
  
    &::placeholder {
      color: #95A4AE;
    }
  }
}