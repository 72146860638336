.calendar-input {

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .red-border {
    input {
      border-color: #D21C1C !important;
    }
  }

  .day-picker-wrapper {
    position: relative;

    .DayPickerInput {
      width: 100%;
    }
  
    input {
      width: 100%;
      height: 44px;
      border-radius: 3px;
      border: 1px solid #DCDFEB;
      padding: 0 12px;
      font-size: 16px;
    
      &::placeholder {
        color: #95A4AE;
      }
    }
  
    img {
      position: absolute;
      top: 12px;
      right: 14px;
      opacity: 0.5;
      pointer-events: none;
    }
  }
}