.shop-navbar {
  position: relative;
  background: #fff;
  box-shadow: 5px 5px 20px rgba(52, 53, 56, 0.05);
  z-index: 3;

  .coupon-bar {
    position: relative;
    background: #41BA9B;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    height: 70px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      display: block;
      text-align: center;
    }

    .coupon-background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .coupon-value {
      color: #FFDB57;
    }

    .coupon-banner-text {
      margin-left: 5px;
      color: #fff;
    }

    .coupon-text {
      display: inline-block;
      background: #fff;
      margin-left: 12px;
      border-radius: 20px;
      padding: 8px 16px;

      @media screen and (max-width: 768px) {
        display: block;
        padding: 4px 12px;
        margin-left: 0;
        width: fit-content;
        margin: 0 auto;
        margin-top: 4px;
      }
    }
  }

  .navbar-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: #fff;

    @media screen and (max-width: 1200px) {
      padding: 0 24px;
    }

    .left {
      height: 100%;
      display: flex;
      align-items: center;

      .menu-icon-wrapper {
        width: 32px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-right: 8px;
        
        @media screen and (min-width: 769px) {
          display: none;
        }

        .menu-icon {
          cursor: pointer;
        }
      }
  
      a {
        display: flex;
        align-items: center;
        margin-right: 32px;
        height: 100%;

        @media screen and (max-width: 768px) {
          margin-right: 8px;
        }

        .company-logo {
          @media screen and (max-width: 768px) {
            width: 120px;
          }
        }
  
        &.link-active {
          font-weight: bold;
          border-bottom: 2px solid #139CFF;
        }
      }
    }

    .right {
      height: 100%;
      display: flex;
      align-items: center;

      .icon-wrapper {
        margin-left: 32px;
       
        @media screen and (max-width: 1200px) {
          margin-left: 16px;
        }
      }

      .language-select-wrapper {
        z-index: 3;
      }

      .link-with-image {
        display: flex;
        align-items: center;
  
        &.link-active {
          font-weight: bold;
          border-bottom: 2px solid #139CFF;
        }

        img {
          margin-right: 8px;

          @media screen and (max-width: 768px) {
            margin-right: 0;
          }
        }

        span {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .mobile-menu {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 3;

    .shadow-wrapper {
      border-bottom: 1px solid #E3E4EF;
      box-shadow: 5px 5px 20px rgba(52, 53, 56, 0.05);
    }

    .links {
      a {
        display: block;
        margin-top: 24px;
        padding: 0px 32px;
      }
    }
  }

  .edibaLogo {
    display: inline-block;
    background-image: url(../../../../assets/logo_ediba.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 70%;
    width: 10em;
  }
}