.about-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 960px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }
  }

  h2 {
    text-align: center;

    @media screen and (max-width: 786px) {
      text-align: left;
      font-size: 24px;
    }
  }

  .images-grid {
    display: grid;
    grid-template-columns: 30fr 18fr 21fr;
    gap: 16px;

    @media screen and (max-width: 786px) {
      grid-template-columns: 1fr;
    }

    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-radius: 5px;

      @media screen and (max-width: 786px) {
        height: auto;
        max-width: 360px;
        margin: 0 auto;
      }
    }
  }

  p.mt32 {
    @media screen and (max-width: 786px) {
      margin-top: 24px;
    }
  }

  p.mt48 {
    @media screen and (max-width: 786px) {
      margin-top: 32px;
    }
  }

  p.text-bigger {
    font-size: 18px;
    font-weight: 500;
    margin-top: 48px;
    text-align: center;

    @media screen and (max-width: 786px) {
      text-align: left;
      margin-top: 32px;
    }
  }
  
}