.email-vouchers-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .columns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {

    }

    .right {

    }
  }

  .table-wrapper {
    margin-top: 48px;

    .table-header {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      background: rgba(239, 240, 246, 0.2);
      border: 1px solid #DCDFEB;
      border-radius: 7px 7px 0 0;

      .table-header-cell {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-right: 1px solid #DCDFEB;
        font-weight: 500;

        &:nth-of-type(7) {
          border-right: none;
        }
      }
    }

    .table-row {
      display: flex;
      width: 100%;
      min-height: 40px;
      border: 1px solid #DCDFEB;
      border-top: none;

      .table-row-cell {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-right: 1px solid #DCDFEB;
        word-break: break-all;
        word-wrap: break-word;

        &:nth-of-type(7) {
          border-right: none;
          justify-content: flex-end;
          color: #D21C1C;
          cursor: pointer;
        }

        &.link-cell {
          justify-content: flex-end;
        
          a {
            color: #139CFF;
          }
        }
      }
    }
  }

  .pagination-wrapper {
    margin-top: 16px;
  }
}