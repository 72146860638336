.creator-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .switch-grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 96px;

    .grid-item {
      background: #fff;
      width: 420px;
      height: 440px;
      box-shadow: 5px 5px 20px #E3E5EE;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 24px;

      .icon {
        margin-bottom: 16px;
      }
    }
  }
}