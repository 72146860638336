.add-user-modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-box {
    position: relative;
    width: 577px;
    background: #fff;
    border: 1px solid #DCDFEB;
    padding: 72px 96px;
    z-index: 2;

    .close-icon {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .text {
      max-width: 400px;
    }
  }
}