.package-details-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .first-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right-area {
      display: flex;
      align-items: center;

      & > div {
        margin-left: 16px;
      }

      .status-filter-wrapper {
        width: 240px;
      }

      .status-select-wrapper {
        width: 180px;
      }

      .button {
        border: 1px solid #139CFF;
        position: relative;
        top: -2px;

        &.inactive {
          background: rgba(239, 240, 246, 0.2);
          border: 1px solid #DCDFEB;
          color: rgba(149, 164, 174, 0.5);
          pointer-events: none;
        }
      }
    }
  }

  .date-line {
    margin-top: 8px;

    img {
      margin-right: 4px;
      position: relative;
      top: 2px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .table-wrapper {
    margin-top: 24px;

    .table {
      margin-bottom: 16px;

      .table-header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        background: rgba(239, 240, 246, 0.2);
        border: 1px solid #DCDFEB;
        border-radius: 7px 7px 0 0;

        .table-header-cell {
          flex: 1;
          height: 100%;
          border-right: 1px solid #DCDFEB;
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: 500;

          &:nth-of-type(6) {
            flex: 0 0 50px;
            border-right: none;
            display: flex;
            justify-content: center;
          }
        }
      }

      .table-row {
        display: flex;
        width: 100%;
        min-height: 40px;
        border: 1px solid #DCDFEB;
        border-top: none;

        &.row-odd {

        }

        &.row-even {
          background: rgba(239, 240, 246, 0.2);
        }

        .table-row-cell {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 8px 16px;
          border-right: 1px solid #DCDFEB;
          word-break: break-all;

          &:nth-of-type(5) {
            justify-content: center;
            color: #139CFF;
          }

          &:nth-of-type(6) {
            flex: 0 0 50px;
            padding: 0;
            border-right: none;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .checkbox {

    .container {
      padding-left: 0;
      margin-right: 0;

      input {
        position: relative;
      }

      .checkmark {
        left: -5px;
        top: -3px;
      }
    }
  }
}