.my-profile-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content-wrapper {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }

    .columns {
      display: flex;

      @media screen and (max-width: 1200px) {
        justify-content: center;
      }

      .left {
        flex: 1;

        @media screen and (max-width: 1200px) {
          max-width: 480px;
        }

        h2 {
          @media screen and (max-width: 1200px) {
            text-align: center;
          }
          @media screen and (max-width: 768px) {
            text-align: left;
            font-size: 20px;
          }
        }

        .input-wrapper {

          @media screen and (max-width: 768px) {
            &.mt32 {
              margin-top: 24px;
            }
            &.mt24 {
              margin-top: 16px;
            }
          }
        }

        .button-wrapper {
          margin-top: 64px;

          @media screen and (max-width: 768px) {
            margin-top: 32px;
          }

          .button {
            border-radius: 20px;
          }
        }
      }

      .right {
        flex: 1;

        @media screen and (max-width: 1200px) {
          flex: 0;
        }
      }
    }
  }

  a {
    color: #2196F3;
    text-decoration: underline;
  }

  .text-smaller {
    font-size: 12px;
  }

  .checkbox label {
    font-size: 14px;
  }
}