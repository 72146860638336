@use "../../../../variables" as var;

.HowItWorks {
    padding: var.$fontSize*4 var.$fontSize*4;
    background-color: var.$backgroundWhite;

    .HowItWorksTitle {
        text-align: center;
    }
}

.Grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var.$fontSize;

    @media screen and (max-width: var.$breakpointLg+px){
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: var.$breakpointNorm+px){
        grid-template-columns: 1fr;
    }

    .GridItem {
        padding: var.$fontSize;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
    }
}

.Icon {
    height: 10vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.cool {
        background-image: url(../../../../assets/icon_1.svg);
    }
    &.wow {
        background-image: url(../../../../assets/icon_2.svg);
    }
    &.omg {
        background-image: url(../../../../assets/icon_3.svg);
    }
    &.och {
        background-image: url(../../../../assets/icon_4.svg);
    }
}

.Description {
    margin-top: var.$fontSize * 1.5;
    text-align: center;
}