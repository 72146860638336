.package-suborder-details-page {
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  
  .first-line {
  
    .status-label {
      margin-left: 24px;
      background: rgba(19, 156, 255, 0.1);
      border-radius: 4px;
      color: #139CFF;
      font-size: 16px;
      font-weight: 600;
      padding: 10px 16px;
    }
  }

  .date-line {
    margin-top: 8px;

    img {
      margin-right: 4px;
      position: relative;
      top: 2px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .columns-2-1 {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 24px;

    .box {
      background: #fff;
      border-radius: 5px;
      padding: 24px;

      .label {
        font-weight: 500;
        color: #95A4AE;
      }

      .text-bigger {
        font-weight: 500;
        font-size: 18px;
      }

      .text {

      }

      hr {
        display: block;
        border: 0;
        border-bottom: 1px solid #EFF0F6;
        margin: 24px 0;

        &.hr-smaller {
          margin: 16px 0;
        }
      }

      .table-header {
        display: flex;

        .table-header-cell {
          font-weight: 500;
          font-size: 16px;
          color: #95A4AE;

          &:nth-of-type(1) {
            flex: 1;
          }

          &:nth-of-type(2) {
            width: 100px;
          }

          &:nth-of-type(3) {
            width: 120px;
          }

          &:nth-of-type(4) {
            width: 60px;
            text-align: right;
          }
        }
      }

      .table-rows {

        .table-row {
          border-bottom: 1px solid #EFF0F6;
          padding: 24px 0;
          display: flex;
          align-items: center;

          &:last-of-type {
            border-bottom: none;
          }

          .table-row-cell {
    
            &:nth-of-type(1) {
              flex: 1;
              display: flex;

              & > img {
                width: 116px;
                height: 84px;
                object-fit: cover;
              }

              .book-detals {
                margin-left: 12px;
                margin-top: 8px;

                .book-title {
                  font-weight: 500;
                  font-size: 18px;
                }

                .book-type {
                  margin-top: 4px;
                  font-size: 14px;
                  color: #95A4AE;
                }
              }
            }
  
            &:nth-of-type(2) {
              width: 100px;
            }
  
            &:nth-of-type(3) {
              width: 120px;
            }
  
            &:nth-of-type(4) {
              width: 60px;
              text-align: right;
            }
          }
        }
      }
    }
  }
}