.clients-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }
  
  .first-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      display: flex;
      align-items: center;

      .search-box-wrapper {
        width: 260px;
      }

      .select-wrapper {
        width: 240px;
      }
    }
  }

  .table-wrapper {
    margin-top: 24px;

    .table {
      margin-bottom: 16px;

      .table-header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        background: rgba(239, 240, 246, 0.2);
        border: 1px solid #DCDFEB;
        border-radius: 7px 7px 0 0;

        .table-header-cell {
          flex: 1;
          height: 100%;
          border-right: 1px solid #DCDFEB;
          display: flex;
          align-items: center;

          & > div {
            font-size: 17px;
            font-weight: 500;
            padding: 0 16px;
          }

          &:nth-of-type(6) {
            flex: 0 0 100px;
            border-right: none;
          }
        }
      }

      .table-row {
        display: flex;
        width: 100%;
        min-height: 40px;
        border: 1px solid #DCDFEB;
        border-top: none;

        &.row-odd {

        }

        &.row-even {
          background: rgba(239, 240, 246, 0.2);
        }

        .table-row-cell {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 8px 16px;
          border-right: 1px solid #DCDFEB;
          word-break: break-all;
          word-wrap: break-word;

          &:nth-of-type(6) {
            flex: 0 0 100px;
            border-right: none;
            color: #D21C1C;
            text-align: center;
            cursor: pointer;
          }

          &.link-cell {
            justify-content: flex-end;
          
            a {
              color: #139CFF;
            }
          }
        }
      }
    }
  }
}