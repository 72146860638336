.shop-sign-up-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content-wrapper {
    margin: 0 auto;
    max-width: 400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }

    @media screen and (max-width: 768px) {
      max-width: none;
    }
  }

  h1 {
    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }

  .login-link {
    
    a {
      color: #139CFF;
    }
  }

  .error-message {
    font-size: 14px;
    margin-top: 32px;
    color: #D21C1C;
  }

  .button-wrapper {
    margin-top: 48px;

    .button {
      background: #139CFF;
      border-radius: 20px;
    }
  }

  .link-wrapper {
    margin-top: 16px;
    text-align: center;

    a {
      font-size: 14px;
    }
  }

  .checkbox > .container {
    font-size: 12px;
    padding-left: 30px;
    padding-top: 5px;

    .checkmark {
      width: 20px;
      height: 20px;

      &::after {
        top: 3px;
        left: 7px;
        width: 4px;
        height: 8px;
      }
    }
  }

  a {
    color: #139CFF;
    text-decoration: underline;
  }

  .checkbox > .input-error {
    margin-top: 8px;
  } 

  .checkbox .checkmark {
    top: 4px;
  } 

  .text-smaller {
    font-size: 10px;
  }

  .password-rules {
    margin-top: 4px;
    font-size: 12px;
  }
}