.products-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }
  
  .filters-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    .right {
      display: flex;

      .select-wrapper {
        width: 200px;
        margin-left: 16px;
      }
    }
  }

  .table-wrapper {
    margin-top: 32px;

    .table {
      margin-bottom: 16px;

      .table-header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        background: rgba(239, 240, 246, 0.2);
        border: 1px solid #DCDFEB;
        border-radius: 7px 7px 0 0;

        .table-header-cell {
          flex: 1;
          height: 100%;
          border-right: 1px solid #DCDFEB;

          &:nth-of-type(1) {
            flex: 0 0 320px;
          }

          &:nth-of-type(6) {
            border-right: none;
          }
        }
      }

      .table-row {
        display: flex;
        width: 100%;
        height: 80px;
        border: 1px solid #DCDFEB;
        border-top: none;

        .table-row-cell {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 0 16px;
          border-right: 1px solid #DCDFEB;
          word-break: break-all;

          &:nth-of-type(1) {
            flex: 0 0 320px;
            
            img {
              height: 61px;
              width: 92px;
              min-width: 92px;
              object-fit: cover;
              margin-right: 16px;
            }
          }

          &:nth-of-type(6) {
            border-right: none;
          }

          &.link-cell {
            justify-content: center;
          
            a {
              color: #139CFF;
            }
          }
        }
      }
    }
  }
}