.shipping-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 70px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .table {
    margin-top: 32px;
    margin-bottom: 16px;

    .table-header {
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      background: rgba(239, 240, 246, 0.2);
      border: 1px solid #DCDFEB;
      border-radius: 7px 7px 0 0;

      .table-header-cell {
        flex: 1;
        height: 100%;
        border-right: 1px solid #DCDFEB;
        display: flex;
        align-items: center;
        padding: 0 16px;

        &:nth-of-type(4) {
          border-right: none;
        }
      }
    }

    .table-row {
      display: flex;
      width: 100%;
      height: 48px;
      border: 1px solid #DCDFEB;
      border-top: none;

      .table-row-cell {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-right: 1px solid #DCDFEB;

        &:nth-of-type(4) {
          border-right: none;
        }

        &.link-cell {
          justify-content: center;
          color: #139CFF;
          cursor: pointer;
        }
      }
    }
  }
}