.client-order-details-page {
  border-top: 1px solid #E3E4EF;
  background: #fff;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content-wrapper {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }
  }

  .breadcrumps-component {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  h2 {
    margin-top: 24px;

    @media screen and (max-width: 768px) {
      margin-top: 0;
      font-size: 20px;
    }
  }
  
  .columns {
    display: flex;
    margin-top: 32px;

    @media screen and (max-width: 1200px) {
      display: block;
    }

    .left {
      flex: 1;

      @media screen and (max-width: 1200px) {
        margin-bottom: 80px;
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 48px;
      }

      .data-section {
        display: flex;
        margin-bottom: 24px;

        .data-section-label {
          width: 150px;
        }

        .data-section-value {
          flex: 1;
        }
      }
    }

    .right {
      flex: 2;
      margin-left: 64px;

      @media screen and (max-width: 1200px) {
        margin-left: 0;
      }

      .products-headers {
        display: flex;
        padding: 0 32px;

        .products-header-cell {

          &:nth-of-type(1) {
            width: 100px;
          }
          &:nth-of-type(2) {
            flex: 1;
          }
          &:nth-of-type(3) {
            width: 130px;
          }
          &:nth-of-type(4) {
            width: 100px;
          }
          &:nth-of-type(5) {
            width: 70px;
          }
          &:nth-of-type(6) {
            width: 70px;
            text-align: right;
          }
        }
      }

      .standard-products {

        .standard-item-box {
          margin-top: 16px;
          background: #FFFFFF;
          box-shadow: 0px 0px 2px #E6E9F0;
          border-radius: 10px;
          padding: 24px 32px;

          &>div:nth-of-type(1) {
            display: flex;
          }

          @media screen and (max-width: 768px) {
            padding: 24px 16px;
          }

          img {
            width: 100px;
            height: 110px;
            object-fit: cover;
          }

          .product-data {
            flex: 1;
            margin-left: 16px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .product-title {
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 4px;
            }

            .product-type {
              font-size: 14px;
            }
          }

          .product-price {
            width: 100px;
          }

          .items-counter-wrapper {
            width: 70px;
          }

          .total-product-price {
            font-weight: 500;
            width: 70px;
            text-align: right;
          }

          .product-status {
            width: 130px;
          }
        }
      }
      
      .personalized-products {

        .personalized-item-box {
          margin-top: 16px;
          background: #FFFFFF;
          box-shadow: 0px 0px 2px #E6E9F0;
          border-radius: 10px;
          padding: 24px 32px;

          @media screen and (max-width: 768px) {
            padding: 24px 16px;
          }

          .box-columns {
            display: flex;

            img {
              width: 100px;
              height: 110px;
              object-fit: cover;
            }
  
            .product-data {
              flex: 1;
              margin-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
  
              .product-title {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 4px;
                padding-right: 8px;
              }
  
              .product-type {
                font-size: 14px;
              }
  
              .remove-button {
                margin-top: 8px;
                font-size: 12px;
                color: #E6125D;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 4px;
                user-select: none;
  
                img {
                  height: 16px;
                  width: auto;
                  margin-right: 4px;
                }
              }
            }
  
            .items-counter-wrapper {
              width: 128px;
  
              .counter-wrapper {
                height: 32px;
                width: 96px;
                border-radius: 5px;
  
                .counter-value {
                  font-size: 16px;
                  padding-top: 3px;
                }
              }
            }
  
            .product-price {
              width: 100px;
            }
  
            .items-counter-wrapper {
              width: 70px;
            }
  
            .total-product-price {
              font-weight: 500;
              width: 70px;
              text-align: right;
            }
            
            .product-status {
              width: 130px;
            }
          }

          .personalization-details {
            margin-top: 16px;

            .personalization-details-line {
              font-size: 12px;
              margin-top: 4px;

              .personalization-details-line-label {
                color: #95A4AE;
              }
  
              .personalization-details-line-text {
                margin-left: 4px;
              }
            }
          }
        }
      }

      .item-data-mobile {
        margin-top: 12px;

        .item-data-mobile-status {
          font-size: 12px;
        }

        .item-data-mobile-wrapper {
          margin-top: 8px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 8px;
          text-align: right;
          font-size: 14px;

          &>div:nth-of-type(1) {
            text-align: left;
          }
          &>div:nth-of-type(3) {
            font-weight: 500;
          }
        }
      }

      .order-summary {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;

        .order-summary-data {
          width: 300px;

          .summary-data-line {
            display: flex;
            margin-top: 16px;

            .summary-data-line-label {
              width: 200px;

              &.bold-text {
                font-weight: 600;
              }
            }

            .summary-data-line-value {
              width: 100px;
              text-align: right;

              &.green-text {
                color: #41BA9B;
              }

              &.bold-text {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
 
}