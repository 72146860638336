.book-details-modal {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding-bottom: 96px;

  .modal-box {
    position: relative;
    margin: 114px auto 0;
    width: 733px;
    background: #fff;
    border: 1px solid #DCDFEB;
    padding: 72px 104px;
    z-index: 2;

    & > .close-icon {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .input-wrapper {
      margin-top: 24px;
    }

    .columns-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
}