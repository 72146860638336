.shop-login-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content-wrapper {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }

    .columns {
      display: flex;

      @media screen and (max-width: 1200px) {
        display: block;
        max-width: 600px;
        margin: 0 auto;
      }

      h2 {
        margin-bottom: 32px;

        @media screen and (max-width: 768px) {
          margin-bottom: 24px;
          font-size: 20px;
        }
      }

      .left {
        flex: 1;
        margin-right: 32px;

        @media screen and (max-width: 1200px) {
          margin-right: 0;
          margin-bottom: 80px;
        }

        @media screen and (max-width: 768px) {
          margin-bottom: 64px;
        }
        
        .input-wrapper {
          max-width: 400px;

          @media screen and (max-width: 1200px) {
            max-width: none;
          }
        }

        .link-wrapper {
          margin-top: 8px;
          text-align: right;
          max-width: 400px;

          @media screen and (max-width: 1200px) {
            max-width: none;
          }
    
          a {
            font-size: 14px;
          }
        }
    
        .login-error {
          margin-top: 16px;
          font-size: 14px;
          color: #D21C1C;
          max-width: 400px;

          @media screen and (max-width: 1200px) {
            max-width: none;
          }
        }
    
        .button-wrapper {
          margin-top: 40px;
          max-width: 400px;

          @media screen and (max-width: 1200px) {
            margin-top: 32px;
            max-width: none;
          }
        }
      }

      .right {
        flex: 1;

        p {
          margin: 32px 0 16px;

          @media screen and (max-width: 768px) {
            margin: 0 0 16px;
          }
        }

        ul {
          margin-bottom: 48px;

          @media screen and (max-width: 1200px) {
            margin-bottom: 32px;
          }

          li {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            img {
              margin-right: 8px;
            }
          }
        }
        
        .button-wrapper {
          margin-bottom: 16px;
          width: 300px;

          @media screen and (max-width: 1200px) {
            width: 100%;
          }

          .button {
            background: #41BA9B;
            border-radius: 20px;
          }

          .button-secondary {
            background: #fff;
            color: #41BA9B;
          }
        }
      }
    }
  }
}