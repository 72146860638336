.faq-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 960px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }
  }

  h2 {
    margin-bottom: 48px;
    text-align: center;

    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
      font-size: 16px;
      text-align: left;
    }
  }
  
  .question-wrapper {
    margin-bottom: 32px;
    background: #F8FCFE;
    padding: 16px 24px;

    @media screen and (max-width: 768px) {
      padding: 12px 12px;
    }

    &.question-opened {
      background: #FFF;
    }

    .question-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
      
      img {
        height: 24px;
        margin-left: 16px;
      }
    }
  }
}