.only-background-page {
  display: grid;
  grid-template-columns: 1fr 450px;
  gap: 16px;

  .page-menu {

    .page-name {
      font-size: 14px;
      color: #9EA2AC;
    }
  }

  .page-content {
    position: relative;
    background: #fff;
    height: 630px;

    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}