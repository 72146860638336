.react-select-option, .react-select-value {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 24px;
  }

  span {
    margin-left: 8px;

    @media screen and (max-width: 1600px) {
      display: none;
    }
  }
}

.custom-indicators-container {
  @media screen and (max-width: 1600px) {
    display: none;
  }
}

.custom-container {
  width: 200px;

  @media screen and (max-width: 1600px) {
    width: 46px;
  }
}

.custom-control {
  @media screen and (max-width: 1400px) {
    width: 46px;

    & > div {
      height: 16px;
      border-radius: 10px;
    }
  }
}