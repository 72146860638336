.cart-page {
  overflow-x: hidden;

  .page-content {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
    display: flex;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
      display: block;
      background: #F8F8FB;
    }

    .left-area {
      flex: 2;
      position: relative;
      background: #F8F8FB;
      padding: 32px 32px 32px 0;

      @media screen and (max-width: 1200px) {
        padding: 32px 0 64px;
      }

      @media screen and (max-width: 768px) {
        padding-bottom: 32px;
      }

      h2 {
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }

      .left-area-background {
        background: #F8F8FB;
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        right: 100%;
        top: 0;

        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      .standard-product-section {
        margin-top: 32px;

        @media screen and (max-width: 768px) {
          margin-top: 24px;
        }

        .standard-item-box {
          margin-top: 16px;
          background: #FFFFFF;
          box-shadow: 0px 0px 2px #E6E9F0;
          border-radius: 10px;
          padding: 24px 32px;

          @media screen and (max-width: 768px) {
            padding: 16px;
          }

          .columns-wrapper {
            display: flex;

            img {
              width: 100px;
              height: 110px;
              object-fit: cover;

              @media screen and (max-width: 768px) {
                width: 80px;
                height: 90px;
              }
            }
  
            .product-data {
              flex: 1;
              margin-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 4px 0;

              @media screen and (max-width: 768px) {
                padding: 0;
              }
  
              .product-title {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 4px;

                @media screen and (max-width: 768px) {
                  font-size: 16px;
                }
              }
  
              .product-type {
                @media screen and (max-width: 768px) {
                  font-size: 14px;
                }
              }
  
              .remove-button {
                margin-top: 8px;
                font-size: 14px;
                color: #E6125D;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 4px;
                user-select: none;
  
                img {
                  height: 16px;
                  width: auto;
                  margin-right: 4px;
                }
              }
            }
  
            .items-counter-wrapper {
              width: 128px;
  
              .counter-wrapper {
                height: 32px;
                width: 96px;
                border-radius: 5px;
  
                .counter-value {
                  font-size: 16px;
                  padding-top: 3px;
                }
              }
            }
  
            .prudct-price {
              font-weight: 500;
              font-size: 20px;
              line-height: 36px;
              width: 90px;
            }
          }
        }
      }

      .personalized-products-section {
        margin-top: 32px;

        @media screen and (max-width: 768px) {
          margin-top: 24px;
        }

        .personalized-item-box {
          margin-top: 16px;
          background: #FFFFFF;
          box-shadow: 0px 0px 2px #E6E9F0;
          border-radius: 10px;
          padding: 24px 32px;
          
          @media screen and (max-width: 768px) {
            padding: 16px;
          }

          .box-columns {
            display: flex;

            img {
              width: 100px;
              height: 110px;
              object-fit: cover;
              
              @media screen and (max-width: 768px) {
                width: 80px;
                height: 90px;
              }
            }
  
            .product-data {
              flex: 1;
              margin-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 4px 0;
  
              .product-title {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 4px;
              
                @media screen and (max-width: 768px) {
                  font-size: 16px;
                }
              }
  
              .product-type {
                @media screen and (max-width: 768px) {
                  font-size: 14px;
                }
              }
  
              .remove-button {
                margin-top: 8px;
                font-size: 12px;
                color: #E6125D;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 4px;
                user-select: none;
  
                img {
                  height: 16px;
                  width: auto;
                  margin-right: 4px;
                }
              }
            }
  
            .items-counter-wrapper {
              width: 128px;
  
              .counter-wrapper {
                height: 32px;
                width: 96px;
                border-radius: 5px;
  
                .counter-value {
                  font-size: 16px;
                  padding-top: 3px;
                }
              }
            }
  
            .prudct-price {
              font-weight: 500;
              font-size: 20px;
              line-height: 36px;
              width: 90px;
            }
          }

          .personalization-details {
            margin-top: 16px;

            .personalization-details-line {
              font-size: 12px;
              margin-top: 4px;

              @media screen and (max-width: 768px) {
                margin-top: 0;
              }

              .personalization-details-line-label {
                color: #95A4AE;
              }
  
              .personalization-details-line-text {
                margin-left: 4px;
              }
            }
          }
        }
      }

      .product-controls-mobile {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        @media screen and (min-width: 769px) {
          display: none;
        }

        .remove-button {
          margin-top: 8px;
          font-size: 12px;
          color: #E6125D;
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-bottom: 4px;
          user-select: none;

          img {
            height: 16px;
            width: auto;
            margin-right: 4px;
          }
        }

        & > div:nth-of-type(2) {
          display: flex;
          align-items: center;
          
          .items-counter-wrapper {
            margin-right: 12px;

            .counter-wrapper {
              height: 28px;
              width: 84px;
              border-radius: 4px;

              .counter-button {
                font-size: 16px;
              }

              .counter-value {
                font-size: 14px;
                padding-top: 0px;
              }
            }
          }

          .prudct-price {
            font-weight: 500;
            font-size: 16px;
          }
        }
      }

      .shipping-info-section {
        margin-top: 32px;

        h3 {
          font-weight: 500;
          font-size: 18px;
          margin-bottom: 12px;

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }

        .shipping-info-box {
          background: #FFFFFF;
          box-shadow: 0px 0px 2px #E6E9F0;
          border-radius: 10px;
          padding: 24px 32px;

          @media screen and (max-width: 768px) {
            padding: 16px;
            font-size: 14px;
          }

          p.font-14 {
            font-size: 14px;
          }
        }
      }
    }
  
    .right-area {
      flex: 1;
      position: relative;
      background: #fff;
      padding: 32px 0 32px 32px;

      @media screen and (max-width: 1200px) {
        margin: 0 -32px;
        padding: 32px;

        & > * {
          max-width: 360px;
          margin-left: auto;
          margin-right: auto;
        } 
      }

      @media screen and (max-width: 768px) {
        & > * {
          max-width: none;
        } 
      }

      .right-area-background {
        background: #fff;
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        left: 100%;
        top: 0;

        @media screen and (max-width: 1200px) {
          display: none;
        }
      }

      h2 {
        margin-bottom: 32px;

        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }

      .line {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.line-bold {
          font-weight: 600;
        }

        .line-label {

        }

        .line-value {

          &.value-green {
            color: #41BA9B;
          }
        }
      }

      hr {
        border: 0;
        border-bottom: 1px solid #EFF0F6;
        margin: 24px 0;

        @media screen and (max-width: 1200px) {
          margin: 24px auto;
        }
      }

      .button-wrapper {
        margin-top: 16px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}