.min-order-value-input {

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .relative-wrapper {
    position: relative;
  
    input {
      width: 100%;
      height: 44px;
      border-radius: 3px;
      border: 1px solid #DCDFEB;
      padding: 0 12px;
      font-size: 16px;
    
      &::placeholder {
        color: #95A4AE;
      }
    }
  
    .types {
      position: absolute;
      top: 7px;
      right: 10px;
      border-radius: 3px;
      overflow: hidden;

      .type {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 30px;
        background: #F6F7FA;
        color: #A2ACB8;
        cursor: pointer;
        border-right: 1px solid #E2ECF8;

        &:last-of-type {
          border-right: 0;
        }

        &.type-active {
          background: #139CFF;
          color: #fff;
        }
      }
    }
  }
}