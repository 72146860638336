.standard-book-details-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 32px;
  padding-bottom: 64px;
  overflow-x: hidden;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }

  .content-wrapper {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
    height: auto;
    min-height: auto;

    &.with-background {
      background: #F8FCFE;
    }

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }
  }

  .breadcrupms-wrapper {
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .columns {
    display: flex;

    @media screen and (max-width: 1200px) {
      display: block;
    }

    .left {
      flex: 1;
      display: flex;

      @media screen and (max-width: 1200px) {
        margin: 0 auto;
        max-width: 550px;
      }

      .thumbnails {
        width: 85px;
        margin-right: 16px;

        @media screen and (max-width: 768px) {
          display: none;
        }

        img {
          width: 85px;
          height: 75px;
          object-fit: cover;
          border-radius: 5px;
          margin-bottom: 16px;
          cursor: pointer;
        }
      }

      .main-image-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #DCDFEB;
        border-radius: 10px; 
        padding: 16px 0;

        .main-image {
          height: 360px;
          width: 280px;
          object-fit: cover;

          @media screen and (max-width: 768px) {
            width: 50vw;
            height: 70vw;
          }
        }

        .arrow {
          padding: 16px;
          cursor: pointer;
        }
      }
    }

    .right {
      flex: 1;
      margin-left: 48px;

      @media screen and (max-width: 1200px) {
        margin: 24px auto 0;
        max-width: 550px;
      }

      .book-title {
        font-weight: 500;
        font-size: 20px;

        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      .book-price-line {
        margin-top: 16px;

        .book-price {
          font-weight: 500;
          font-size: 40px;

          @media screen and (max-width: 768px) {
            font-size: 32px;
          }
        }

        .book-price-label {
          font-size: 13px;
          color: #95A4AE;
          margin-left: 8px;
        }
      }

      .items-count-wrapper {
        margin-top: 32px;

        @media screen and (max-width: 768px) {
          margin-top: 16px;
        }
      }

      .button-wrapper {
        margin-top: 32px;
        max-width: 320px;

        button {
          padding-left: 48px;
          padding-right: 48px;
        }
      }
    }
  }

  .desctiption-section {
    margin-top: 32px;
    padding-top: 24px;
    padding-bottom: 24px;
    
    .description-title {
      margin-bottom: 16px;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .similar-products-section {
    padding-top: 24px;
    padding-bottom: 24px;

    @media screen and (max-width: 856px) {
      margin-left: -32px;
      margin-right: -32px;
    }
    
    h2 {
      margin-bottom: 24px;

      @media screen and (max-width: 856px) {
        font-size: 20px;
        margin-left: 32px;
      }
    }
  }
}