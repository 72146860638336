@use "../../../../variables" as var;

.SeeMore {
    padding: var.$fontSize*4 var.$fontSize*4;
    background-color: var.$backgroundWhite;

    .SeeMoreTitle {
        text-align: center;
    }
}

.container {
    text-align: center;
}

.image {
    display: inline-block;
    height: var.$fontSize * 5;
    width: auto;
    margin: var.$fontSize * 2;
    @media screen and (max-width: var.$breakpointSm+px) {
        max-height: var.$fontSize * 3;;
        margin: var.$fontSize * 2 0;
    }
}
