@use "../../../../variables" as var;

.BooksList {

    margin-top: var.$fontSize*4;

    @media screen and (max-width: var.$breakpointNorm+px){
        background-color: var.$primaryBlue;
        margin-top: 0;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        margin: 0 5vw;
        gap: 5vw;

        @media screen and (max-width: var.$breakpointLg+px){
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: var.$breakpointNorm+px){
            grid-template-columns: 1fr;
        }
    }
}


.frills {
    display: none;
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/cta_frills_desktop.svg");
    transform: translateY(-5px);
    z-index: -1;
    height: 16vw;

    svg {
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    @media screen and (max-width: var.$breakpointNorm+px){
        display: block;
    }
}