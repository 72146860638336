.shop-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  
  .banner {
    background: #93C4BC;
    height: 150px;
    position: relative;

    @media screen and (max-width: 1200px) {
      height: 120px;
    }

    @media screen and (max-width: 768px) {
      height: auto;
      padding: 16px 32px;
    }

    .banner-inner {
      margin: 0 auto;
      padding: 0 128px;
      max-width: 1400px;
      height: 100%;
      display: flex;
      align-items: center;

      @media screen and (max-width: 1200px) {
        padding: 0 32px;
      }

      @media screen and (max-width: 768px) {
        height: auto;
        padding: 16px 0;
      }

      h1 {
        color: #fff;
        z-index: 1;

        @media screen and (max-width: 768px) {
          text-align: center;
          font-size: 20px;
          width: 100%;
        }
      }
    }

    img {
      height: 150px;
      position: absolute;
      top: 0;
      right: 0;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }

  .page-content {
    margin: 0 auto;
    padding: 64px 128px;
    max-width: 1400px;
    background: #fff;

    @media screen and (max-width: 1200px) {
      padding: 64px 32px;
    }

    @media screen and (max-width: 768px) {
      padding: 32px 16px;
    }
    
    .products-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
      }

      @media screen and (max-width: 768px) {
        gap: 16px;
      }

      .product {
        padding: 24px;
        border-bottom: 2px solid #E3E5EE;
        display: flex;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          padding: 16px;
        }

        &:hover {
          border-radius: 20px;
          border-bottom: 2px solid transparent;
          box-shadow: 5px 5px 20px #E3E5EE;

          button {
            opacity: 1 !important;
          }
        }

        .left {
          margin-right: 24px;

          img {
            height: 220px;
            width: 180px;
            object-fit: cover;

            @media screen and (max-width: 1300px) {
              height: 180px;              
              width: 150px;
            }

            @media screen and (max-width: 1200px) {
              height: 220px;
              width: 180px;
            }

            @media screen and (max-width: 768px) {
              height: 170px;
              width: 130px;
            }

            @media screen and (max-width: 480px) {
              height: 120px;
              width: 90px;
            }
          }
        }

        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .product-title {
            font-weight: 500;
            font-size: 22px;

            @media screen and (max-width: 768px) {
              font-size: 16px;
            }
          }

          .product-description {
            font-size: 14px;
            margin-top: 16px;
            margin-bottom: 16px;

            @media screen and (max-width: 768px) {
              margin-top: 12px;
              margin-bottom: 12px;
            }
          }

          .product-price-line {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media screen and (max-width: 768px) {
              flex-direction: column;
              align-items: flex-end;
            }

            .proudct-price {
              font-size: 24px;

              @media screen and (max-width: 768px) {
                font-size: 20px;
              }
            }

            button {
              display: inline-flex;
              width: 145px;
              justify-content: center;
              align-items: center;
              position: relative;
              font-size: 14px;
              height: 40px;
              opacity: 0;

              @media screen and (max-width: 768px) {
                margin-top: 8px;
                opacity: 1;
                height: 34px;
              }

              img {
                height: 24px;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
}