@use "../../../../variables" as var;

.KidsPhotos {
    background-color: var.$backgroundWhite;
    height: 30vw;
}

.Grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Kid {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    &.Kid1 {
        background-image: url(../../../../assets/kid_photo_ewelina_lifestyle.webp);
    }
    &.Kid2 {
        background-image: url(../../../../assets/kid_photo_kamila.webp);
    }
    &.Kid3 {
        background-image: url(../../../../assets/kid_photo_makusiowo.webp);
    }
    &.Kid4 {
        background-image: url(../../../../assets/kid_photo_mommyll.webp);
    }
}