.product-details-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }
  
  .first-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .deactivate-button {
      font-size: 18px;
      color: #D21C1C;
      cursor: pointer;
    }
  }

  .columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    margin-top: 48px;

    .left {

      .main-image-wrapper {
        
        .drag-and-drop-input {
          width: 485px;
          height: 323px;
        }
      }

      .additional-images-wrapper {
        width: 485px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 15px;
        
        .drag-and-drop-input {
          width: 110px;
          height: 73px;
        }
      }

      .link-wrapper {
        margin-top: 24px;
        width: 485px;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 44px;
          background: #0BAA97;
        }
      }
    }

    .right {

      .input-wrapper {

      }

      .columns-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
      }
    }
  }

  .history-table-wrapper {
    margin-top: 48px;

    hr {
      border: 0;
      border-bottom: 1px solid #DCDFEB;
    }

    .table-title {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  .seo-fields-wrapper {
    margin-top: 2em;
    border-radius: 4px;
    background-color: #E0E0E0;
    padding: 1em;
    .title {
      margin-bottom: 1em;
    }
  }

}