.packages-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .first-line {

  }

  .table-wrapper {
    margin-top: 24px;

    .table {
      margin-bottom: 16px;

      .table-header {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        background: rgba(239, 240, 246, 0.2);
        border: 1px solid #DCDFEB;
        border-radius: 7px 7px 0 0;

        .table-header-cell {
          flex: 1;
          height: 100%;
          border-right: 1px solid #DCDFEB;
          display: flex;
          align-items: center;
          font-size: 17px;
          font-weight: 500;

          &:nth-of-type(2) > div {
            padding: 0 16px;
          }

          &:nth-of-type(5) {
            border-right: none;
          }
        }
      }

      .table-row {
        display: flex;
        width: 100%;
        min-height: 40px;
        border: 1px solid #DCDFEB;
        border-top: none;

        &.row-odd {

        }

        &.row-even {
          background: rgba(239, 240, 246, 0.2);
        }

        .table-row-cell {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 8px 16px;
          border-right: 1px solid #DCDFEB;
          word-break: break-all;

          &:nth-of-type(4) {
            justify-content: center;
            color: #139CFF;
            cursor: pointer;
          }

          &:nth-of-type(5) {
            border-right: none;
            justify-content: center;
            color: #139CFF;
          }
        }
      }
    }
  }
}