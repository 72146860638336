@use "../../../../variables" as var;

.ShopFooter {
    position: relative;
    background-color: var.$backgroundWhite;
}

.InnerFooter {
    position: relative;
    min-height: 50vw;
    @media screen and (max-width: var.$breakpointNorm+px){
        min-width: unset;
        min-height: unset;
    }

}

.ImageBackground {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    max-width: var.$breakpointLg+px;
    background-image: url(../../../../assets/background_cloud_yellow.svg);
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding-left: var.$fontSize * 6;
    padding-bottom: 5vw;
    @media screen and (max-width: var.$breakpointNorm+px){
        position: relative;
        left: unset;
        bottom: unset;
        height: unset;
        background-image: none;
        background-color: var.$backgroundLightYellow;
    }
    @media screen and (max-width: var.$breakpointSm+px){
        padding-left: 0;
    }
}

.FooterContent {
    @media screen and (max-width: var.$breakpointNorm+px){
        padding: var.$fontSize * 2;
        padding-bottom: var.$fontSize * 5;
    }
    @media screen and (max-width: var.$breakpointSm+px){
        margin: 0 auto;
    }
}

.Line1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var.$fontSize * 4;
    margin-bottom: var.$fontSize * 2;
    .text {
        font-size: var.$fontLarge;
    }

    .contactData {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var.$fontSize * 2;
        background: #FFFFFF;
        box-shadow: 5px 5px 20px #E4F1F8;
        border-radius: 10px;
        padding: var.$fontSize var.$fontSize*2;
    }
    @media screen and (max-width: var.$breakpointLg+px){
        gap: var.$fontSize;
        margin-bottom: var.$fontSize;
        .text {
            font-size: var.$fontSize;
            padding-right: var.$fontSize;
        }
    }
    @media screen and (max-width: 900px){
        font-size: 0.85em;
    }
    @media screen and (max-width: var.$breakpointNorm+px){
        display: none;
    }
}

.Line2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var.$fontSize * 4;
    margin-top: var.$fontSize;
    @media screen and (max-width: 900px){
        font-size: 0.85em;
    }
    @media screen and (max-width: var.$breakpointSm+px){
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: flex-start;
    }
    .social {
        @media screen and (max-width: var.$breakpointNorm+px){
            display: none;
        }
        .socialHeader {
            @media screen and (max-width: var.$breakpointLg+px){
                display: none;
            }
        }
    }
}
.socialMobile {
    display: none;
    @media screen and (max-width: var.$breakpointNorm+px){
        padding-top: var.$fontSize;
        display: block;
    }
    @media screen and (max-width: var.$breakpointSm+px){
        text-align: center;
    }
}
.icon {
    height: 40px;
    width: 40px;
}

.EdibaLogo {
    background-image: url(../../../../assets/logo_ediba.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 6vw;
    width: 24vw;
    right: var.$fontSize;
    bottom: var.$fontSize;
}
