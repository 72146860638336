.discount-input {
  position: relative;
  
  input {
    width: 100%;
    height: 44px;
    border-radius: 3px;
    border: 1px solid #DCDFEB;
    padding: 0 12px;
    font-size: 16px;
  
    &::placeholder {
      color: #95A4AE;
    }
  }

  .input-button {
    position: absolute;
    top: 3px;
    right: 3px;
    background: #41BA9B;
    border-radius: 3px;
    border: 0;
    padding: 0 12px;
    height: calc(100% - 6px);
    cursor: pointer;
    color: #fff;
  }

  &.input-disabled {
    user-select: none;
    pointer-events: none;

    .input-button {
      background: #DCDFEB;
    }
  }
}