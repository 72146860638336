.admin-navbar {
  background: #fff;
  height: 70px;

  .navbar-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .right {
      height: 100%;
      display: flex;
      align-items: center;
    }

    a {
      display: flex;
      align-items: center;
      margin-right: 32px;
      height: 100%;

      &.link-active {
        font-weight: bold;
        border-bottom: 2px solid #139CFF;
      }
    }

    .navbar-dropdown {
      position: relative;
      height: 100%;
      margin-right: 32px;
      display: flex;
      align-items: center;
      cursor: default;

      &.link-active {
        font-weight: bold;
        border-bottom: 2px solid #139CFF;
      }

      &:hover {
        .navbar-dropdown-box {
          display: block;
        }
      }
      
      .navbar-dropdown-box {
        cursor: pointer;
        display: none;
        position: absolute;
        top: 60px;
        left: -24px;
        background: #FFFFFF;
        box-shadow: 5px 5px 20px #E3E5EE;
        border-radius: 5px;
        width: 250px;
        z-index: 1;
        overflow: hidden;
        font-weight: normal;

        a {
          height: 48px;
          padding: 0 24px;
          width: 100%;

          &:hover {
            background: #EFF0F6;
          }
        }
      }
    }

    .link-button {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background: #139CFF;
      color: #fff;
      border-radius: 3px;
      height: 44px;
      padding: 0 16px;
    }
  }
  
  .logout-icon {
    cursor: pointer;
  }
}