.kid-photo-drag-and-drop {

  .drag-and-drop-input {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 0;
    min-height: 140px;

    &.blue-background {
      background: rgba(142, 198, 253, 0.05);
    }
  
    &:before {
      content: "";
      position: absolute;
      border: 5px dashed #139CFF;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      z-index: 1;
      pointer-events: none;
    }

    &.red-border:before {
      border-color: #D21C1C;
    }
  
    .drop-active-overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      z-index: 3;
    }
  
    .browse-button {
      color: #95A4AE;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      img {
        margin-bottom: 16px;
      }
  
      label {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  
    .after-upload-features {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 16px 16px;
  
      .left {
        display: flex;
        align-items: center;
  
        .file-preview {
          height: 100px;
          width: auto;
          border-radius: 5px;
        }
      }
  
      .right {
        display: flex;
        align-items: center;
  
        label {
          display: flex;
          align-items: center;
          margin-left: 24px;
          color: #139CFF;
        }
      }
    }
  }

  .edit-photo-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    .modal-box {
      background: #fff;
      width: 600px;
      position: relative;
      padding: 64px 32px;

      @media screen and (max-width: 768px) {
        width: 100%;
        height: 100vh;
        padding: 32px;
        margin: 0;
      }

      .close-icon {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
      }

      h2 {
        margin-bottom: 32px;
      }

      .react-crop-wrapper {
        max-height: 60vh;
        display: flex;
        justify-content: center;

        .ReactCrop__image {
          max-height: 60vh;
        }
      }

      .button-wrapper {
        margin-top: 48px;
        display: flex;
        justify-content: center;

        .button {
          width: 200px;

          &.button-disabled {
            pointer-events: none;
            user-select: none;
            color: #95A4AE;
            background: #F9F9F9;
          }
        }
      }
    }
  }
}