.similar-products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px;

  @media screen and (max-width: 1200px) {
    overflow-x: auto;
  }

  @media screen and (max-width: 856px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  .products-box {
    min-width: 170px;

    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
    }

    .similar-product-price {
      font-weight: 500;
      font-size: 24px;
    }
  }
}