.items-counter-component {

  .counter-label {
    font-size: 14px;
    margin-bottom: 4px;
  }

  .counter-wrapper {
    display: flex;
    width: 126px;
    height: 42px;
    border: 1.3125px solid #EFF0F6;
    border-radius: 6.5625px;
  
    .counter-button {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      user-select: none;
      cursor: pointer;
    }
  
    .counter-value {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F8F9FB;
      border-left: 1.3125px solid #EFF0F6;
      border-right: 1.3125px solid #EFF0F6;
      font-weight: 500;
      font-size: 21px;
      user-select: none;
    }
  }
}