.textarea-input {

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  textarea {
    width: 100%;
    height: 114px;
    border-radius: 3px;
    border: 1px solid #DCDFEB;
    padding: 12px;
    font-size: 16px;
    resize: none;
  
    &::placeholder {
      color: #95A4AE;
    }
  }

  .bottom-area {
    display: flex;
    justify-content: space-between;
  }

  .textarea-counter {
    color: #95A4AE;
    font-size: 12px;
    text-align: right;
  }
}