.labeled-select {

  .react-select-value {

    span {
      margin-left: 0;
    }

    .value-label {
      margin-right: 4px;
      color: #95A4AE;
    }
  }
}