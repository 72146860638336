.confirmation-modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding-bottom: 96px;
  z-index: 3;

  .modal-box {
    position: relative;
    margin: 160px auto 0;
    width: 577px;
    background: #fff;
    border: 1px solid #DCDFEB;
    padding: 72px 96px;
    z-index: 2;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100vh;
      padding: 32px;
      margin: 0;
    }

    h2 {
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .close-icon {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .text {
      max-width: 400px;
    }

    .button-wrapper {
      @media screen and (max-width: 768px) {
        &.mt64 {
          margin-top: 32px;
        }
      }
    }
  }
}