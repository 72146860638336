.order-data-page {
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }

  .page-content-wrapper {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }

    @media screen and (max-width: 768px) {
      padding: 0;
    }

    h2 {
      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    .columns {
      display: flex;

      @media screen and (max-width: 1200px) {
        display: block;
      }

      .left {
        flex: 1;

        .box {
          position: relative;
          background: #FFFFFF;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
          border-radius: 10px;
          padding: 32px 40px;
          margin-bottom: 32px;

          @media screen and (max-width: 768px) {
            border-radius: 0;
            box-shadow: none;
            padding: 32px;
          }

          .boxHeader.expanded {
            position: relative;
            @media screen and (max-width: 768px) {
              background-color: #FFFFFF;
              position: sticky;
              left: 0;
              top: 0;
              padding: 16px 0;
              z-index: 10;
            }
          }
        }

        .invoice-form {

          .copy-data-button {
            color: #139CFF;
            margin-top: 24px;
            margin-bottom: 32px;
            cursor: pointer;
          }
        }

        .form-summary {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .left {

            & > div {
              margin-bottom: 8px;
            }
          }

          .right {
            width: fit-content;
            height: fit-content;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
              font-size: 14px;
              color: #139CFF;
              margin-left: 8px;
              position: relative;
              top: 1px;
            }
          }
        }

        .input-wrapper {
          margin-top: 16px;
          display: flex;

          @media screen and (max-width: 768px) {
            display: block;
          }

          & > div:nth-of-type(2) {
            margin-left: 16px;

            @media screen and (max-width: 768px) {
              margin-left: 0;
              margin-top: 16px;
            }
          }

          .text-input {
            width: 100%;
          }

          .select-input {
            width: 100%;
          }
        }

        .button-wrapper {
          margin-top: 24px;
          display: flex;
          justify-content: flex-end;

          .button {
            border-radius: 20px;
            padding: 0 48px;

            @media screen and (max-width: 768px) {
              width: 100%;
              font-size: 16px;
            }
          }
        }

        .payment-box {
          border: 1.5px solid #41BA9B;
          border-radius: 10px;
          display: flex;
          padding: 24px;

          @media screen and (max-width: 768px) {
            padding: 16px;
            font-size: 14px;
          }

          img {
            margin-right: 24px;

            @media screen and (max-width: 768px) {
              width: 50px;
              margin-right: 16px;
            }
          }

          .payment-box-title {
            font-weight: 500;
          }
        }

        .standard-product-section {
          margin-top: 32px;

          .standard-item-box {
            margin-top: 16px;
            background: #FFFFFF;
            box-shadow: 0px 0px 2px #E6E9F0;
            border-radius: 10px;
            padding: 24px 32px;

            &>div:nth-of-type(1) {
              display: flex;
            }

            @media screen and (max-width: 768px) {
              padding: 24px 16px;
            }

            img {
              width: 100px;
              height: 110px;
              object-fit: cover;
            }

            .product-data {
              flex: 1;
              margin-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .product-title {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 4px;
                padding-right: 12px;
              }

              .product-type {
                font-size: 14px;

                @media screen and (max-width: 768px) {
                  font-size: 12px;
                }
              }
            }

            .product-price {
              width: 100px;
            }

            .items-counter-wrapper {
              width: 70px;
            }

            .total-product-price {
              font-weight: 500;
              width: 80px;
              text-align: right;
            }
          }
        }
      }

      .personalized-products-section {
        margin-top: 32px;

        .personalized-item-box {
          margin-top: 16px;
          background: #FFFFFF;
          box-shadow: 0px 0px 2px #E6E9F0;
          border-radius: 10px;
          padding: 24px 32px;

          @media screen and (max-width: 768px) {
            padding: 24px 16px;
          }

          .box-columns {
            display: flex;

            img {
              width: 100px;
              height: 110px;
              object-fit: cover;
            }

            .product-data {
              flex: 1;
              margin-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .product-title {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 4px;
                padding-right: 12px;
              }

              .product-type {
                font-size: 14px;

                @media screen and (max-width: 768px) {
                  font-size: 12px;
                }
              }

              .remove-button {
                margin-top: 8px;
                font-size: 12px;
                color: #E6125D;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 4px;
                user-select: none;

                img {
                  height: 16px;
                  width: auto;
                  margin-right: 4px;
                }
              }
            }

            .items-counter-wrapper {
              width: 128px;

              .counter-wrapper {
                height: 32px;
                width: 96px;
                border-radius: 5px;

                .counter-value {
                  font-size: 16px;
                  padding-top: 3px;
                }
              }
            }

            .product-price {
              width: 100px;
            }

            .items-counter-wrapper {
              width: 70px;
            }

            .total-product-price {
              font-weight: 500;
              width: 80px;
              text-align: right;
            }
          }

          .personalization-details {
            margin-top: 16px;

            .personalization-details-line {
              font-size: 12px;
              margin-top: 4px;

              .personalization-details-line-label {
                color: #95A4AE;
              }

              .personalization-details-line-text {
                margin-left: 4px;
              }
            }
          }
        }
      }

      .item-data-mobile {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        text-align: right;
        font-size: 14px;

        &>div:nth-of-type(1) {
          text-align: left;
        }
        &>div:nth-of-type(3) {
          font-weight: 500;
        }
      }

      .right {
        width: 400px;
        margin-left: 32px;

        @media screen and (max-width: 1200px) {
          width: auto;
          margin-left: 0;
        }

        .summary-box {
          background: #FFFFFF;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
          border-radius: 10px;
          padding: 32px 40px;

          @media screen and (max-width: 768px) {
            border-radius: 0;
            padding: 32px;
          }

          .line {
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.line-bold {
              font-weight: 600;
            }

            &.discount-input-wrapper {
              display: block;
              margin: 24px 0;

              @media screen and (max-width: 1200px) {
                margin: 24px auto;
              }
            }

            .line-value {
              min-width: 90px;
              text-align: right;

              &.value-green {
                color: #41BA9B;
              }
            }

            .error-line {
              margin-top: 8px;
              font-size: 14px;
              color: red;
            }
          }

          hr {
            border: 0;
            border-bottom: 1px solid #EFF0F6;
            margin: 24px 0;
          }

          .button {
            margin-top: 32px;

            &.button-inactive {
              color: #95A4AE;
              background: #F9F9F9;
              pointer-events: none;
            }
          }
        }

        .payment-info {
          margin-top: 24px;
          color: #95A4AE;
          font-size: 10px;
          padding: 0 40px;

          @media screen and (max-width: 1200px) {
            padding: 0;
            text-align: center;
          }

          @media screen and (max-width: 768px) {
            margin-top: 0;
            padding: 24px 32px 48px;
            background: #fff;
          }

          a {
            color: #139CFF;
          }
        }
      }
    }
  }
}
