.reset-password-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    min-height: 0;
  }

  @media screen and (max-width: 768px) {
   background: #fff;
  }

  .reset-password-box {
    position: relative;
    padding: 128px 108px 92px;
    background: #fff;
    width: 520px;
    box-shadow: 5px 5px 20px #E3E5EE;
    border-radius: 10px;

    @media screen and (max-width: 1200px) {
      margin: 64px 32px;
      padding: 64px;
    }

    @media screen and (max-width: 768px) {
      margin-top: 32px;
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      width: auto;
    }

    h1 {
      @media screen and (max-width: 768px) {
        font-size: 24px;
      }
    }

    .language-dropdown-wrapper {
      position: absolute;
      top: 24px;
      right: 24px;
    }

    .input-wrapper {
      margin-top: 24px;
    }

    .button-wrapper {
      margin-top: 48px;
    }

    a {
      display: block;
      margin-top: 24px;
      color: #139CFF;
    }
  }
}