.standard-book {
  padding-bottom: 64px;
  
  .details-box {
    margin: 48px auto 0;
    width: 733px;
    background: #fff;
    border: 1px solid #DCDFEB;
    border-radius: 10px;
    padding: 72px 104px;

    .input-wrapper {
      margin-top: 24px;

      .label {
        font-size: 14px;
        color: #252A31;
      }
    }

    .columns-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }

    .book-images {
      margin-top: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px 24px;
    }
  }

  .seo-fields-wrapper {
    margin-top: 2em;
    border-radius: 4px;
    background-color: #E0E0E0;
    padding: 1em;
    .title {
      margin-bottom: 1em;
    }
  }
}