.banner-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .button {
    max-width: 250px;
  }
}