.cover-page {
  display: grid;
  grid-template-columns: 1fr 450px;
  gap: 16px;

  .page-menu {

    .page-name {
      font-size: 14px;
      color: #9EA2AC;
    }
  }

  .page-content {
    position: relative;
    background: #fff;
    height: 630px;

    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .photo-placeholder {
      position: absolute;
      top: 50.25%;
      left: 47.61%;
      width: 35.71%;
      height: 25.25%;
    }

    .cover-text {
      position: absolute;
      top: 33.7%;
      left: 14.3%;
      width: 71.4%;
      height: 8.4%;
      border-radius: 3px;
      padding: 12px;
      white-space: pre-wrap;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      font-size: 24px;
    }

    .cover-text:before {
      content: "";
      position: absolute;
      border: 5px dashed #B1B3B4;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
    }
  }
}