.add-voucher-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 24px;
  padding-bottom: 64px;
  min-height: calc(100vh - 80px);

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .first-line {
    
    .back-link {
      font-size: 14px;
      display: flex;
      align-items: center;

      img {
        height: 24px;
        position: relative;
        top: -1px;
      }
    }

    h2 {
      margin-top: 32px;
    }
  }

  .input-wrapper {

    &.w400 {
      width: 400px;
    }

    &.w250 {
      width: 250px;
    }
  }

  .button-wrapper {
    width: 400px;
  }
}