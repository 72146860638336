.profile-menu {
  height: 70px;
  display: flex;

  .big-screens {
    @media screen and (max-width: 1400px) {
      display: none;
    }
  }

  .menu {
    position: relative;
    cursor: default;
    
    &:hover {
      .menu-box {
        display: flex !important;
      }
    }

    .menu-box {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      width: 240px;
      background: #FFFFFF;
      box-shadow: 5px 5px 20px rgba(52, 53, 56, 0.16);
      border-radius: 10px;
      cursor: default;
      z-index: 3;
      flex-direction: column;
      overflow: hidden;

      .menu-position {
        display: flex;
        align-items: center;
        height: 48px;
        cursor: pointer;
        padding: 0 16px;

        &:hover {
          background: #139CFF;
          color: #fff;

          img {
            filter: brightness(100) sepia(0) hue-rotate(0) saturate(100);
          }
        }
      }
    }
  }
}