.client-orders-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  @media screen and (max-width: 1200px) {
    padding-top: 32px;
  }
  
  .page-content-wrapper {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;

    @media screen and (max-width: 1200px) {
      padding: 0 32px;
    }
  }

  h2 {
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .table-wrapper {
    margin-top: 32px;

    @media screen and (max-width: 768px) {
      margin-top: 16px;
    }

    .table-headers {
      display: flex;

      .table-header {
        flex: 1;
        padding: 12px 24px;
      }
    }

    .table-rows {

      .table-row {
        display: flex;

        @media screen and (max-width: 768px) {
         display: block;
         padding: 16px 24px;

         a {
           display: block;
           margin-top: 32px;
           text-decoration: underline;
         }
        }

        &.row-odd {
          background: #F9F9F9;
        }

        .table-row-cell {
          flex: 1;
          padding: 12px 24px;
        }
      }
    }
  }

}