.publish-modal {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding-bottom: 96px;
  z-index: 3;

  .modal-box {
    position: relative;
    margin: 160px auto 0;
    width: 577px;
    background: #fff;
    border: 1px solid #DCDFEB;
    padding: 72px 96px;
    z-index: 2;

    .close-icon {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }

    .text {
      max-width: 400px;
    }
  }
}