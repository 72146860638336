@use '../../../variables' as var;
@use "sass:math";

.ModalWrapper {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;

    background-color: var.$modalBackground;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.Modal {
    z-index: 10;
    position: relative;
    min-width: var.$fontSize * 5;
    max-width: 95vw;
    min-height: var.$fontSize * 5;
    padding: var.$fontSize * 2;
    background-color: var.$backgroundWhite;
}

.CloseIcon {
    position: absolute;
    top: 0;
    right: 0;
    height: var.$fontSize * 2;
    width: var.$fontSize * 2;
    background-color: transparent;
    background-image: url(../../../assets/close_icon.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    outline: inherit;
}

.Title {
    margin-bottom: var.$fontSize;
}

.Content {

}

.Actions {
    margin-top: var.$fontSize;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .actionButton {
        min-width: 200px;
        font-size: var.$fontSize;
        color: var.$backgroundWhite;
        background-color: var.$primaryBlue;
        border-radius: math.div(var.$fontSize, 2);
        padding: math.div(var.$fontSize, 2) var.$fontSize;
        border: none;
        box-shadow: none;
    }
}

