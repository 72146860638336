.pagination-component {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
  user-select: none;

  .pagination-arrow {
    cursor: pointer;

    &.pagination-arrow-left {
      margin-right: 12px;
    }

    &.pagination-arrow-right {
      margin-left: 12px;
    }
  }

  .pagination-item {
    padding: 0 6px;
    margin: 0 6px;
    color: #B8B8D4;
    cursor: pointer;
    padding-top: 3px;

    &.pagination-item-selected {
      color: #1e1e25;
      border: 1px solid #000000;
      border-radius: 3px;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.pagination-item-inactive {
      cursor: default;
    }
  }
}