.orders-page {
  background: #fff;
  border-top: 1px solid #E3E4EF;
  padding-top: 48px;
  padding-bottom: 64px;

  .content-wrapper-inner {
    margin: 0 auto;
    padding: 0 128px;
    max-width: 1400px;
  }

  .first-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      display: flex;

      .search-box-wrapper {
        width: 260px;
      }

      .select-wrapper {
        width: 240px;
        margin-right: 16px;
      }
    }
  }

  .table-wrapper {
    margin-top: 24px;

    .table {
      border-collapse: collapse;
      display: table;
      margin-bottom: 16px;
      border: 1px solid #DCDFEB;

      .table-header {
        display: table-row;
        background: rgba(239, 240, 246, 0.2);

        .table-header-cell {
          border: 1px solid #DCDFEB;
          flex: 1;
          height: 100%;
          display: table-cell;
          align-items: center;
          font-size: 17px;
          font-weight: 500;

          .header-text {
            font-size: 14px;
          }

          .sortable-header {
            padding: 16px 12px;
          }

          .not-sortable-header {
            padding: 16px 12px;
            font-size: 14px;
          }

          &:nth-of-type(1) {
            min-width: 75px;
          }
          &:nth-of-type(3) {
          }
          &:nth-of-type(4) {
            min-width: 100px;
          }
          &:nth-of-type(5) {
            min-width: 90px;
          }
          &:nth-of-type(6) {
          }
          &:nth-of-type(7) {
          }
          &:nth-of-type(8) {
          }
          &:nth-of-type(9) {
            border-right: none;
          }
        }
      }

      .table-row {
        display: table-row;
        width: 100%;
        min-height: 40px;
        border: 1px solid #DCDFEB;
        border-top: none;
        font-size: 14px;

        &.row-odd {

        }

        &.row-even {
          background: rgba(239, 240, 246, 0.2);
        }

        .table-row-cell {
          flex: 1;
          display: table-cell;
          align-items: center;
          padding: 8px 12px;
          border-right: 1px solid #DCDFEB;
          &:nth-of-type(1) {
          }
          &:nth-of-type(3) {
          }
          &:nth-of-type(4) {
          }
          &:nth-of-type(5) {
          }
          &:nth-of-type(6) {
          }
          &:nth-of-type(7) {
          }
          &:nth-of-type(8) {
          }
          &:nth-of-type(9) {
            border-right: none;
          }

          &.link-cell {
            justify-content: flex-end;

            a {
              color: #139CFF;
            }
          }

          &.status-cell {
            font-size: 0.8em;
            flex-direction: column;
            .package-link {
              margin-top: 0.5em;
            }
          }
        }
      }
    }
  }
}
