.drag-and-drop {

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .drag-and-drop-input {
    position: relative;
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 0;
  
    &:before {
      content: "";
      position: absolute;
      border: 5px dashed #95A4AE;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
      z-index: 1;
      pointer-events: none;
    }

    &.red-border:before {
      border-color: #D21C1C;
    }
  
    .drop-active-overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      z-index: 3;
    }
  
    .browse-button {
      color: #139CFF;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
  
      label {
        cursor: pointer;
      }
    }
  
    .after-upload-features {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px;
  
      .left {
        display: flex;
        align-items: center;
  
        .file-preview {
          height: 64px;
          width: auto;
          max-width: 128px;
          object-fit: cover;
        }
  
        .file-data {
  
          .file-name {
            max-width: 100px;
            word-break: break-all;
          }
  
          .file-dimensions {
            margin-top: 4px;
            font-size: 12px;
            color: #86888C;
          }
        }
      }
  
      .right {
        display: flex;
        align-items: center;
  
        label {
          display: flex;
          align-items: center;
        }
  
        .refresh-icon {
          cursor: pointer;
        }
  
        .close-icon {
          cursor: pointer;
        }
      }
    }
  }
}