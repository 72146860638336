* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

a {
  color: inherit;
  text-decoration: inherit;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
}

h2 {
  font-weight: 600;
  font-size: 24px;
}

.button {
  background: #139CFF;
  border: 0;
  width: 100%;
  height: 44px;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  border-radius: 3px;
  padding: 0 16px;
  transition: all 0.3s;

  &.auto-width {
    width: initial;
  }

  &.button-secondary {
    border: 1px solid #139CFF;
    background: #fff;
    color: #139CFF;
  }

  &.rounded {
    border-radius: 100px;
  }

  &.button-success {
    background: #41BA9B;
    transition: all 0.3s;
    pointer-events: none;
    cursor: default;
  }

  &.button-disabled {
    pointer-events: none;
    user-select: none;
    color: #95A4AE;
    background: #F9F9F9;
  }
}

.mt4 {
  margin-top: 4px;
}

.mt8 {
  margin-top: 8px;
}

.mt12 {
  margin-top: 12px;
}

.mt16 {
  margin-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.mt48 {
  margin-top: 48px;
}

.mt64 {
  margin-top: 64px;
}

.mb24 {
  margin-bottom: 16px;
}

.mb32 {
  margin-bottom: 32px;
}

.ml16 {
  margin-left: 16px;
}

.mr8 {
  margin-right: 8px;
}

.mr16 {
  margin-right: 16px;
}

.red-border {
  border-color: #D21C1C !important;
}

.input-error {
  color: #D21C1C;
  font-size: 14px;
  margin-top: 4px;
}

.big-screens {
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.small-and-medium-screens {
  @media screen and (min-width: 1201px) {
    display: none;
  }
}

.small-screens {
  @media screen and (min-width: 769px) {
    display: none;
  }
}

.medium-and-big-screens {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.medium-screens {
  display: none;
  @media screen and (min-width: 769px) and (max-width: 1200px) {
    display: block;
  }
}