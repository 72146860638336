.background-image-menu {
  width: 350px;

  .menu-header {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;

    img {

    }

    .menu-header-text {
      flex: 1;
      margin-left: 8px;
    }
  }

  .menu-options {
    margin: 12px 0 16px 16px;
  }
}