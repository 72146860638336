@font-face {
  font-family: 'POL_KOMIKAX';
  src: url('assets/fonts/POL_KOMIKAX.ttf');
}

@font-face {
  font-family: 'POL_Wonder_Comic';
  src: url('assets/fonts/POL_Wonder_Comic.ttf');
}

@font-face {
  font-family: 'Linotte-SemiBold';
  src: url('assets/fonts/Linotte-SemiBold.otf');
}

@font-face {
  font-family: 'coiny-regular';
  src: url('assets/fonts/coiny-regular.ttf');
}

/* @import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@300;400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


.App, textarea, input, button {
  font-family: 'Poppins', sans-serif;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}