.cart-button {
  height: 70px;
  display: flex;

  .big-screens {
    @media screen and (max-width: 1400px) {
      display: none;
    }
  }

  &:hover {
    .hover-box {
      display: flex !important;
    }
  }
  
  .link-with-image {
    position: relative;
    cursor: default;

    .cart-counter {
      position: absolute;
      top: 19px;
      left: 14px;
      width: 16px;
      height: 16px;
      background: #139CFF;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 10px;
      padding-top: 1px;
    }

    .hover-box {
      display: none;
      position: absolute;
      top: 60px;
      right: 0px;
      width: 300px;
      background: #FFFFFF;
      box-shadow: 5px 5px 20px rgba(52, 53, 56, 0.16);
      border-radius: 10px;
      cursor: default;
      z-index: 3;

      .cart-preview {
        padding: 24px 16px;
        width: 100%;

        .cart-items {

          .cart-item {
            display: flex;
            margin-bottom: 32px;

            img {
              height: 100px;
              width: 75px;
              object-fit: cover;
            }

            .cart-item-data {
              flex: 1;
              margin-left: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .cart-item-title {
                font-weight: 500;
                font-size: 14px;
              }

              .cart-item-type {
                margin-top: 8px;
                font-size: 12px;                
                color: #95A4AE;
              }

              .cart-item-remove-button {
                margin-top: 8px;
                font-size: 12px;
                color: #E6125D;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 4px;
                user-select: none;

                img {
                  height: 16px;
                  width: auto;
                  margin-right: 4px;
                }
              }
            }
          }
        }

        .price-line {
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          font-weight: 600;
        }

        .button {
          margin-top: 24px;
          width: 100%;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .no-items {
        min-height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .no-items-title {
          font-weight: 500;
          font-size: 17px;
        }
  
        .no-items-text {
          margin-top: 24px;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}