@use "../../../variables" as var;

.bannerComponent {
  background: var.$secondaryYellow;
  padding: 16px 32px;
  text-align: center;
  white-space: pre-line;
  font-size: var.$bannerFontSize;
  font-weight: 500;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
}