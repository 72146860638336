.drag-and-drop-2 {

  .input-label {
    margin-bottom: 8px;
    font-size: 14px;
  }

  .drag-and-drop-input {
    position: relative;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #EFF0F6;
    border: 1px solid #EFF0F6;

    &.red-border:before {
      border-color: #D21C1C;
    }
  
    .drop-active-overlay {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      z-index: 3;
    }
  
    .browse-button {
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #252A31;
  
      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
  
      label {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        span.color-red {
          color: red;
        }
      }
    }
  
    .after-upload-features {
      width: 100%;
      height: 100%;
      position: relative;
  
      .file-preview {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      label {
        display: flex;
        align-items: center;
      }

      .close-icon {
        cursor: pointer;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }
  }
}