.standard-page {
  display: grid;
  grid-template-columns: 1fr 450px;
  gap: 16px;

  .page-menu {

    .page-name {
      font-size: 14px;
      color: #9EA2AC;
    }
  }

  .page-content {
    position: relative;
    background: #fff;
    height: 630px;

    .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .photo-placeholder {
      position: absolute;

      &.right-page-top-left {
        top: 19.1%;
        left: 15.2%;
        width: 22%;
        height: 15.6%;
      }
  
      &.right-page-botton-left {
        top: 50.3%;
        left: 24.6%;
        width: 22%;
        height: 15.6%;
      }
  
      &.right-page-top-right {
        top: 11.1%;
        left: 62.3%;
        width: 22%;
        height: 15.6%;
      }
  
      &.right-page-botton-right {
        top: 62.9%;
        left: 51.2%;
        width: 22%;
        height: 15.6%;
      }

      &.left-page-top-left {
        top: 19.1%;
        left: 22%;
        width: 22%;
        height: 15.6%;
      }
  
      &.left-page-botton-left {
        top: 50.3%;
        left: 31.3%;
        width: 22%;
        height: 15.6%;
      }
  
      &.left-page-top-right {
        top: 11.2%;
        left: 68.7%;
        width: 22%;
        height: 15.6%;
      }
  
      &.left-page-botton-right {
        top: 62.9%;
        left: 57.8%;
        width: 22%;
        height: 15.6%;
      }
    }

    .text {
      position: absolute;
      width: 50%;
      border-radius: 3px;
      padding: 12px;
      white-space: pre-wrap;
      overflow: hidden;
      font-size: 12px;

      &.right-page-top-left {
        top: 11.1%;
        left: 11.1%;
        width: 48.9%;
        min-height: 18.8%;
      }
  
      &.right-page-botton-left {
        top: 55.1%;
        left: 11.1%;
        width: 48.9%;
        min-height: 18.8%;
      }
  
      &.right-page-top-right {
        top: 30.1%;
        left: 33.4%;
        width: 48.9%;
        min-height: 18.8%;
      }
  
      &.right-page-botton-right {
        top: 70.1%;
        left: 33.4%;
        width: 48.9%;
        min-height: 18.8%;
      }

      &.left-page-top-left {
        top: 11.1%;
        left: 17.75%;
        width: 44.4%;
        min-height: 18.8%;
      }
  
      &.left-page-botton-left {
        top: 55.1%;
        left: 17.75%;
        width: 48.9%;
        min-height: 18.8%;
      }
  
      &.left-page-top-right {
        top: 30.1%;
        left: 40.05%;
        width: 48.9%;
        min-height: 18.8%;
      }
  
      &.left-page-botton-right {
        top: 70.1%;
        left: 40.05%;
        width: 48.9%;
        min-height: 18.8%;
      }
    }

    .text:before {
      content: "";
      position: absolute;
      border: 5px dashed #B1B3B4;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
    }
  }
}